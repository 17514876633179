/*Added this code. Anand_POW-492. June 14 2023.. Code Start */

import React from 'react';

const ManageSecurityAddRoleConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/securitysetup/addrole',
			component:React.lazy(() => import('./tabs/NewModal')) 
        }
	]
};

export default ManageSecurityAddRoleConfig;

/*Added this code. Anand_POW-492. June 14 2023.. Code End */