import { TextFieldFormsy } from '@fuse/core/formsy';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
// import * as authActions from 'app/auth/store/actions';
import Formsy from 'formsy-react';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { ToastContainer, toast } from 'react-toastify';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import axios from 'axios';
import * as API from 'app/constants/ApiConstants';
import * as APP from 'app/constants/AppConstants';
import aes256 from 'aes256';
import './login.css';

function FirebaseLoginTab(props) {
	// const dispatch = useDispatch();
	const login = useSelector(({ auth }) => auth.login);
	const user = useSelector(({ auth }) => auth.user);
	const [getkey, setKey] = useState("prja2021cG93ZXJyZWNydWl0MjAyMA==");
	const [isFormValid, setIsFormValid] = useState(false);
	const [message, errorFunction] = useState(false);
	const formRef = useRef(null);
	const history = useHistory()
	
	useEffect(() => {


		
		// if (login.error && (login.error.erroMessage)) {
		// 	errorFunction(true)
		// 	disableButton();
		// }
		// else {
		// 	errorFunction(false)
		// }

		// //PAGE ASSIGN --------------------------------------------------------------------------------------
		// if (user.userRole) {
		// 	let localdata = {
		// 		"roleId": user.userRole
		// 	}
		// 	localStorage.setItem('Role', user.userRole)
		// 	// dispatch(authActions.PageAssignActions(localdata))

		// 	// authActions.PageAssignActions(localdata, res => {
		// 	// 	let pageassignArr = []
		// 	// 	if (res) {
		// 	// 		res.map((item) => {
		// 	// 			pageassignArr.push(item.pageId)
		// 	// 		})
		// 	// 		localStorage.setItem("Page", pageassignArr.toString())
		// 	// 	}
		// 	// 	else if (res.length == 0) {
		// 	// 		history.push('/')
		// 	// 	}
		// 	// })
		// }
		// --------------------------------------------------------------------------------------------------
		// if (user.userRole == 1) {
		// 	history.push("/dashboard/dashboard")
		// 	window.location.reload()
		// }
		// else if (user.userRole == 2) {
		// 	history.push("/dashboard/analytics")
		// 	window.location.reload()
		// }
		// else if (user.userRole == 4) {
		// 	history.push("/dashboard/teamlead/analytics")
		// 	window.location.reload()
		// }
		// else {
		// 	history.push("/")
		// }
	}, [login.error, user]);

	
	function disableButton() {
		setIsFormValid(false);
	}

	function enableButton() {
		setIsFormValid(true);
	}

	function setSession(authResult) {
		if (authResult && authResult.accessToken && authResult.idToken) {
			// Set the time that the access token will expire at
			const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
			localStorage.setItem('access_token', authResult.accessToken);
			localStorage.setItem('id_token', authResult.idToken);
			localStorage.setItem('expires_at', expiresAt);
			
		}
	};

	function callCheck() {
		if (localStorage.getItem('Page')) {
			history.push("/dashboard")
		}
	}





	function handleSubmit(model, props) {
		if (model.usename !== "teamlead" && model.password !== "teamlead@123") {
			var decrypted = values.password;
			var crypto = require('crypto')
			var key = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
			var cc = crypto.createCipher('aes-128-ecb', new Buffer(key));
			var encrypted = Buffer.concat([cc.update(decrypted, 'utf8'), cc.final()]).toString('base64');

			let data = {
				"username": model.username,
				"password": encrypted,
				 "phno" :props.phno,
				
			}

			// var cipher = aes256.createCipher(getkey)
			// var encrypted = cipher.encrypt(values.password)
			// var decrypted = cipher.decrypt(encrypted)
			// dispatch(authActions.submitLogin(data))
			return new Promise((resolve, reject) => {
				axios.post(API.LOGIN_URL, data)
					.then(response => {
						if (response.status === APP.SUCCESS) {
							localStorage.setItem("Role", response.data.userRole)
							localStorage.setItem("Name", response.data.username)
							localStorage.setItem("userPhoneNumber", response.data.phno)
							
							localStorage.setItem("Page", response.data.pages.toString())
							localStorage.setItem("buid", response.data.activeBu)
							localStorage.setItem("Write", response.data.write)
							setSession(response.data.token)
							localStorage.setItem('bearer_token', response.data.token)

								
							// localStorage.setItem('bearer_token', "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImV4cCI6MTYxMTI1MjcxMiwiaWF0IjoxNjExMjQ1NTEyfQ.XgGa1Obi8DN-Z-zPdbMKR_8Kdo__ZhMkiafuQVDr2vf7xM6ozCREvl_17ODNXCYoKVq_YEidyW8Krt3_V5Jdnw")
							axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
						
						// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
							if (response.data.pages.split(",").includes("23")) {
						// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
								// history.push("/dashboard")
								history.push("/analyticsdashboard")
								// window.location.reload()
							}
						// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
							else if (response.data.pages.split(",").includes("24")) {
						// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
								console.log('Recruiter')
								history.push("/recruiter/dashboard")
								// window.location.reload()
							}
						// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
							else if (response.data.pages.split(",").includes("25")) {
						// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
								console.log('TeamLead')
								history.push("/teamlead/dashboard")
								// window.location.reload()
							}
							else {
								history.push("/")
							}
						} else {
							console.log("error")
							// reject(response.data.error);
						}
					},

					
						(error) => {
							toast(error.response.data.erroMessage, {
								position: toast.POSITION.TOP_CENTER,
								className: 'foo-bar'
							});
						}
					)
			})
		}
	}
	// password
	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const [values, setValues] = React.useState({
		amount: '',
		password: '',
		weight: '',
		weightRange: '',
		showPassword: false,
	});
	const handleChange = (prop) => (event) => {
		errorFunction(false)
		setIsFormValid(true)
		setValues({ ...values, [prop]: event.target.value });
	};
	return (
		<div className="w-full">
			<ToastContainer />
			<Formsy
				onValidSubmit={handleSubmit}
				onValid={enableButton}
				onInvalid={disableButton}
				ref={formRef}
				className="flex flex-col justify-center w-full"
			>
				<TextFieldFormsy
					style={{ width: '81%', marginBottom: "1.6rem !important", padding: "18.5px 14px !important" }}
					className="mb-16"
					type="text"
					name="username"
					label="Username"
					validations={{ minLength: 4 }}
					validationErrors={{ minLength: 'Min character length is 4' }}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<PermIdentityIcon color="action">
									{/* email */}
								</PermIdentityIcon>
							</InputAdornment>
						)
					}}
					variant="outlined"
					required
				/>

				<FormControl variant="outlined" style={{ marginTop: "10px" }}>
					<InputLabel htmlFor="outlined-adornment-password">Password*</InputLabel>
					<OutlinedInput
						id="outlined-adornment-password"
						type={values.showPassword ? 'text' : 'password'}
						value={values.password}
						onChange={handleChange('password')}
						validations={{
							minLength: 3
						}}
						validationErrors={{
							minLength: 'Min character length is 3'
						}}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end"
									style={{ padding: '7px' }}
								>
									{values.showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>

						}
						labelWidth={70}
					/>
				</FormControl>

				{message === true && !user.userRole ?
					<Alert severity="error">{login.error.erroMessage}</Alert>
					: ''
				}
				<Button
					type="submit"
					variant="contained"
					color="primary"
					className="w-full mx-auto normal-case mt-16"
					aria-label="LOG IN"
					disabled={!isFormValid}
					value="firebase"
				// onClick={handleSubmit}
				>
					Log in
				</Button>

			</Formsy>
		</div>
	);
}

export default FirebaseLoginTab;
