import * as forgotpasswordapi from 'app/services/forgotpasswordservices';
import * as UserActions from './user.actions';

export const FORGOTPASS_SUCCESS = "FORGOTPASS_SUCCESS"
export const FORGOTPASS_ERROR = "FORGOTPASS_ERROR"
export const FORGOTUSERNAME_SUCCESS ="FORGOTUSERNAME_SUCCESS"
export const FORGOTUSERNAME_ERROR="FORGOTUSERNAME_ERROR"

export function forgotpassActions(data) {
	console.log("forgotdataact", data)
	return dispatch =>
		forgotpasswordapi.forgotpassword(data)
			.then(user => {
				dispatch(UserActions.forgotPassData(user));

				return dispatch({
					type: FORGOTPASS_SUCCESS,
					payload:user
				});
			})
			.catch(error => {
				console.log("error", error)
				return dispatch({
					type: FORGOTPASS_ERROR,
					payload: error
				});
			});
}
export function forgotuserActions(data) {
	console.log("forgotusernameact", data)
	return dispatch =>
	forgotpasswordapi.forgotusername(data)
		.then(user => {
			dispatch(UserActions.forgotUserNameData(user));

			return dispatch({
				type: FORGOTUSERNAME_SUCCESS,
				payload:user
			});
		})
		.catch(error => {
			console.log("erroraction", error)
			return dispatch({
				type: FORGOTUSERNAME_ERROR,
				payload: error
			});
		});
}