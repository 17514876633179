import Organizational from './dashboards/example/ExampleConfig.js';
import BusinessUnit from './dashboards/businessunits/BusinessUnitConfig.js';
import ClientJobStatus from './dashboards/clientjobstatus/ClientJobStatusConfig.js';
import CandidateJobStatusConfig from './dashboards/candidatejobstatus/CandidateJobStatusConfig.js';
import WorkAuthorization from './dashboards/workauthorization/WorkAuthorizationConfig.js';
import SourceNamesConfig from './dashboards/sourcenames/SourceNamesConfig.js';
import ChatbotType from './dashboards/chatbottype/ChatBotConfig.js';
import ManageSecurityRole from './securitysetup/managesecurityrole/ManageSecurityRoleConfig.js';
import ManageSecurityAddRoleConfig from './securitysetup/managesecurityrole/ManagerSecurityAddRoleConfig.js';
import ManageSecurityUser from './securitysetup/managesecurityuser/ManageSecurityUserConfig.js';
import ManageSecurityAddUserConfig from "./securitysetup/managesecurityuser/ManageSecurityAddUser.js"
import JobStatus from './dashboards/jobstatus/JobStatusConfig.js';
import AnalyticsDashboard from './dashboards/analyticsdashboard/AnalyticsDashboardConfig.js';
import EmploymentType from './dashboards/employmenttype/EmploymentTypeConfig.js';
import SetupExpenses from './dashboards/setupexpenses/SetupExpensesConfig.js';
// Added this code for  POW-20. Prasanna. August 25 2022.. Code Start 
import ManagerDetails from './dashboards/managerdetails/ManagerDetailsConfig.js';
// Prasanna. August 25 2022. Code End


const appsConfigs = [
    Organizational,
    BusinessUnit,
    ClientJobStatus,
    JobStatus,
    SetupExpenses,
    CandidateJobStatusConfig,
    WorkAuthorization,
    ChatbotType,
    ManageSecurityRole,
    ManageSecurityAddRoleConfig,
    ManageSecurityUser,
    ManageSecurityAddUserConfig,
    SourceNamesConfig,
    AnalyticsDashboard,
    EmploymentType,
    // Added this code for  POW-20. Prasanna. August 25 2022.. Code Start 
    ManagerDetails
    // Prasanna. August 25 2022. Code End

]
export default appsConfigs;