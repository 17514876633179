import * as Actions from '../actions';

const initialState = {
	success: false,
};

const forgotpass = (state = initialState, action) => {
	switch (action.type) {
		case Actions.FORGOTPASS_SUCCESS: {
			return {
				// ...initialState,
                success: true,
                forgotpasssuccess:action.payload
			};
		}
		case Actions.FORGOTPASS_ERROR: {
            
			return {
				success: false,
				forgotpasserror: action.payload
			};
        }
        case Actions.FORGOTUSERNAME_SUCCESS: {
			return {
				// ...initialState,
                success: true,
                forgotusernamesuccess : action.payload
			};
		}
		case Actions.FORGOTUSERNAME_ERROR: {
            
			return {
				success: false,
				forgotusernameerror: action.payload
			};
		}
		default: {
			return state;
		}
	}
};

export default forgotpass;