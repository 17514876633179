import * as chatbotapi from 'app/services/dashboard/chatbotservices';

export function chatbotlistActions(data,res) {
    chatbotapi.chatBotList(data,(response)=>{
		if (response.token) {
			chatbotapi.chatBotList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}


//create 
export function chatbotCreateActions(data,res){
	chatbotapi.chatbotCreate(data,(response)=>{
		if (response.token) {
			chatbotapi.chatbotCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit 
export function chatbotEditActions(data,res){
	chatbotapi.chatbotEdit(data,(response)=>{
		if (response.token) {
			chatbotapi.chatbotEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

//change status
export function chatbotstatusActions(data,res) {
	chatbotapi.chatbotStatusUpdate(data,(response)=>{
		if (response.token) {
			chatbotapi.chatbotStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})			
}