import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
import * as APP from 'app/constants/AppConstants'

export function forgotpassword(data) {
    return new Promise((resolve, reject) => {
        axios.get(API.FORGOT_URL + "?id=" + data + "&code=true")
            .then(response => {
                if (APP.SUCCESS === response.data.statusCode) {
                    resolve(response.data);
                }
                // else {
                // 	reject(response.data.message);
                // }
            },
                (error) => {
                     reject(error.response.data)
                    // reject(error.data.message)
                    // console.log("error.data",error.response.data)
                }
            );
    });
}
export function forgotusername(data) {
    return new Promise((resolve, reject) => {
        axios.get(API.FORGOT_URL + "?id=" + data + "&code=false")
        .then(response => {
            if (APP.SUCCESS === response.data.statusCode) {
                resolve(response.data);
            }
            // else {
            // 	reject(response.data.message);
            // }
        },
            (error) => {
                reject(error.response.data)
                // reject(error.data.message)
                // console.log("error.data",error.response.data)
            }
        );
    });

}