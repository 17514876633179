import React from 'react';

const UploadJobConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/upload/uploadjobs',
			component:React.lazy(() => import('./UploadJob')) 
        }
	]
};

export default UploadJobConfig;