import * as managerdetailsapi from 'app/services/dashboard/managerdetailsservices';
// import * as UserActions from './user.actions'

export const MANAGERDETAILS_LIST = "MANAGERDETAILS_LIST"
export const MANAGERDETAILSLIST_ERROR = "MANAGERDETAILSLIST_ERROR"

export function managerdetailslistActions(data, res) {
	// console.log("managerdetailslistact", data)
	// return dispatch =>
	managerdetailsapi.managerdetailsList(data, (response) => {
		if (response.token) {
			managerdetailsapi.managerdetailsList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
	
}
//create business unit
export function managerdetailscreateActions(data, res) {
	managerdetailsapi.managerdetailsCreate(data, (response) => {
		if (response.token) {
			managerdetailsapi.managerdetailsCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit business unit
export function managerdetailseditActions(data, res) {
	managerdetailsapi.managerdetailsEdit(data, (response) => {
		if (response.token) {
			managerdetailsapi.managerdetailsEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//search business unit
export function managerdetailssearchActions(data, res) {
	managerdetailsapi.managerdetailsSearch(data, (response) => {
		if (response.token) {
			managerdetailsapi.managerdetailsSearch(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//change status
export function managerdetailsstatusActions(data, res) {
	managerdetailsapi.managerdetailsStatusUpdate(data, (response) => {
		if (response.token) {
			managerdetailsapi.managerdetailsStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
export function profilebusinessActions(data, res) {
	managerdetailsapi.profilebusinessAPI(data, (response) => {
		if (response.token) {
			managerdetailsapi.profilebusinessAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}