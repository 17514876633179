import * as sourcenameapi from 'app/services/dashboard/servicenames';
// import * as UserActions from './user.actions'


export function searchnameslistActions(data,res) {
	sourcenameapi.searchNamesList(data,(response)=>{
		if (response.token) {
			sourcenameapi.searchNamesList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}
//create business unit
export function searchnamescreateActions(data,res){
	sourcenameapi.searchNamesCreate(data,(response)=>{
		if (response.token) {
			sourcenameapi.searchNamesCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit business unit
export function searchnameseditActions(data,res){
	sourcenameapi.searchNamesEdit(data,(response)=>{
		if (response.token) {
			sourcenameapi.searchNamesEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//change status
export function searchnamesstatusActions(data,res) {
	sourcenameapi.searchNamesStatusUpdate(data,(response)=>{
		if (response.token) {
			sourcenameapi.searchNamesStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})			
}

