import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
import * as APP from 'app/constants/AppConstants'
import Logoutsession from '../../logout/logoutsession.js'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshTokenApi from '../../refreshtoken/refreshtokenapi'


//Added this code for  POW-515. Dharmateja. June 10 2023.. Code Start
export function uploadfiles(formData, databool) {
    if (databool == true) {
        return new Promise((resolve, reject) => {
            axios.get(API.GOTOMANAGEJOBREDIRECT_URL + "?JobWithoutCand=" + formData.JobWithoutCand + "&page_no=" + formData.page_no + "&limit=" + formData.limit + "&sortby=" + formData.sortby + "&orderby=" + formData.orderby,
                {
                    headers: {
                        'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                    }
                }
            )
                .then(response => {
                    if (APP.SUCCESS == response.status) {
                        resolve(response.data)
                    }
                },
                    (error) => {
                        if (APP.LOGOUTERROR == error.response.status) {
                            Logoutsession()
                            toast("Your session is timed out, not able to proceed further", {
                                position: toast.POSITION.TOP_CENTER,
                                className: 'foo-bar'
                            })
                        }
                        else {
                            reject(error.response.data)
                        }
                    }
                );
        });
    }
    else {
        return new Promise((resolve, reject) => {
            axios.post(API.UPLOADFILE_URL, formData,
                {
                    headers: {
                        'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                    }
                }
            )
                .then(response => {
                    if (APP.SUCCESS == response.status) {
                        resolve(response.data)
                    }
                },
                    (error) => {
                        if (APP.LOGOUTERROR == error.response.status) {
                            Logoutsession()
                            toast("Your session is timed out, not able to proceed further", {
                                position: toast.POSITION.TOP_CENTER,
                                className: 'foo-bar'
                            })
                        }
                        else {
                            reject(error.response.data)
                        }
                    }
                );
        });
    }
}
//Dharmateja. June 10 2023.. Code End

//upload file
export function uploadfile(formData, databool) {
    if (databool == true) {
        return new Promise((resolve, reject) => {
            axios.get(API.GOTOJOBDETAILS_URL + "?jobId=" + formData,
                {
                    headers: {
                        'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                    }
                }
            )
                .then(response => {
                    if (APP.SUCCESS == response.status) {
                        resolve(response.data)
                    }
                },
                    (error) => {
                        if (APP.LOGOUTERROR == error.response.status) {
                            Logoutsession()
                            toast("Your session is timed out, not able to proceed further", {
                                position: toast.POSITION.TOP_CENTER,
                                className: 'foo-bar'
                            })
                        }
                        // else if (APP.TOKENERROR == error.response.data.erroCode) {
                        //     refreshTokenApi(error.response.data.refreshToken, (resp) => {
                        //         localStorage.setItem('bearer_token', resp.token)
                        //         res(resp)
                        //     })
                        // }
                        else {
                            reject(error.response.data)
                        }
                    }
                );
        });
    }
    else {
        return new Promise((resolve, reject) => {
            axios.post(API.UPLOADFILE_URL, formData,
                {
                    headers: {
                        'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                    }
                }
            )
                .then(response => {
                    if (APP.SUCCESS == response.status) {
                        resolve(response.data)
                    }
                },
                    (error) => {
                        if (APP.LOGOUTERROR == error.response.status) {
                            Logoutsession()
                            toast("Your session is timed out, not able to proceed further", {
                                position: toast.POSITION.TOP_CENTER,
                                className: 'foo-bar'
                            })
                        }
                        // else if (APP.TOKENERROR == error.response.data.erroCode) {
                        //     refreshTokenApi(error.response.data.refreshToken, (resp) => {
                        //         localStorage.setItem('bearer_token', resp.token)
                        //         res(resp)
                        //     })
                        // }
                        else {
                            reject(error.response.data)
                        }
                    }
                );
        });
    }
}
// Without reducer

export function uploadfileNo(formData, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.UPLOADFILE_URL, formData,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    resolve(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR == error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR == error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        reject(error.response.data)
                    }
                }
            );
    });
}


export function gotomatchresults(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MATCHRESULTS_URL + "?jobId=" + data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    resolve(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR == error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR == error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        reject(error.response.data)
                    }
                }
            );
    });
}
//matchhistory
export function matchhistory(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MATCHHISTORYSEARCH_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR == error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR == error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//chatbot history
export function chatbothistory(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.CHATBOTSEARCH_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR == error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR == error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//viewchatbot
export function viewchatbot(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.VIEWCHATBOT_URL + "?candguid=" + data.candguid,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR == error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR == error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//botlist
export function botlist(res) {
    return new Promise((resolve, reject) => {
        axios.get(API.BOTLIST_URL,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR == error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR == error.response.status) {
                        refreshTokenApi()
                    }
                    else if (APP.TOKENERROR == error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function graberList(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.UPLOADGRABER_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR == error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR == error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

/* Added this code for  POW-431. Prasanna. November 23 2022.. Code Start */
export function uploadHistoryList(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.UPLOADHISTORY_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR == error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR == error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
/* Prasanna. November 23 2022. Code End */

export function graberSend(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.GRABERSEND_URL + "?grabberFilePath=" + data.filepath + "&uploadid=" + data.id,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    console.log("error", error.response)
                    if (APP.LOGOUTERROR == error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR == error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
