export const SUCCESS =200
export const SEARCHPROGRES =206
export const FAILURE =400
export const TOKENERROR = 403
export const LOGOUTERROR = 401
export const FROALAEDITORKEY="QFF4nB12B7A7C7E5B3eitprspnrkmpC8eheuF2C1G1I1A10C1E6A1F6C5=="
export const tiny_key = "5473jekvhgwv95b6ha730ie2aujfzysc62uz1kqga2um1sl6"
export const INTERNAL_ERROR = 500


