import * as businessunitsapi from 'app/services/dashboard/businessunitservices';
// import * as UserActions from './user.actions'

export const BUSINESSUNIT_LIST = "BUSINESSUNIT_LIST"
export const BUSINESSUNITLIST_ERROR = "BUSINESSUNITLIST_ERROR"

export function businessunitlistActions(data, res) {
	// console.log("businessunitlistact", data)
	// return dispatch =>
	businessunitsapi.businessUnitList(data, (response) => {
		if (response.token) {
			businessunitsapi.businessUnitList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
	// .then(user => {
	//     console.log("businessunitlistuser", user)
	// 	// dispatch(UserActions.businessUnitListData(user));
	// 	return dispatch({
	// 		type: BUSINESSUNIT_LIST,
	// 		payload:user
	// 	});
	// })
	// .catch(error => {
	// 	console.log("error", error)
	// 	return dispatch({
	// 		type: BUSINESSUNITLIST_ERROR,
	// 		payload: error
	// 	});
	// });
}
//create business unit
export function businessunitcreateActions(data, res) {
	businessunitsapi.businessUnitCreate(data, (response) => {
		if (response.token) {
			businessunitsapi.businessUnitCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit business unit
export function businessuniteditActions(data, res) {
	businessunitsapi.businessUnitEdit(data, (response) => {
		if (response.token) {
			businessunitsapi.businessUnitEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//search business unit
export function businessunitsearchActions(data, res) {
	businessunitsapi.businessUnitSearch(data, (response) => {
		if (response.token) {
			businessunitsapi.businessUnitSearch(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//change status
export function businessunitstatusActions(data, res) {
	businessunitsapi.businessStatusUpdate(data, (response) => {
		if (response.token) {
			businessunitsapi.businessStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
export function profilebusinessActions(data, res) {
	businessunitsapi.profilebusinessAPI(data, (response) => {
		if (response.token) {
			businessunitsapi.profilebusinessAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

