import axios from "axios";
import * as API from "app/constants/ApiConstants";
import * as APP from "app/constants/AppConstants";
import Logoutsession from "../../logout/logoutsession.js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import refreshTokenApi from "../../refreshtoken/refreshtokenapi";

export function managejoblistAPI(data, res) {
  return new Promise((resolve, reject) => {
    axios
      .get(API.MANAGEJOBLIST_URL + "?jobid=" + data.jobid, {
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("bearer_token"),
        },
      })
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR == error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}

export function managejobSearchAPI(data, res) {
  return new Promise((resolve, reject) => {
    axios
      .post(API.MANAGEJOBSEARCH_URL, data, {
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("bearer_token"),
        },
      })
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR === error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}
// Job Details
// export function managejobDetailsAPI(data, res) {
//     console.log("apidata",data)
//     return new Promise((resolve, reject) => {
//         axios.get(API.MANAGEJOBDETAILS_URL, + data.jobid,
//             {
//                 headers: {
//                     'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
//                 }
//             }
//         )
//             .then(response => {
//                 console.log("response", response)
//                 if (APP.SUCCESS == response.status) {
//                     resolve(response.data)
//                 }
//             },
//                 // (error) => {
//                 //     console.log("error", error.response)
//                 //     if (APP.LOGOUTERROR == error.response.status) {
//                 //         Logoutsession()
//                 //     }
//                 //     else if (APP.TOKENERROR == error.response.status) {
//                 //         Logoutsession()
//                 //     }
//                 //     else {
//                 //         reject(error.response.data)
//                 //     }
//                 // }
//             );
//     });
// }

export function managejobDetailsAPI(data, res) {
  return new Promise((resolve, reject) => {
    axios
      .get(API.MANAGEJOBDETAILS_URL + "?jobid=" + data.jobid, {
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("bearer_token"),
        },
      })
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR === error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}

export function getJobStatusList(res) {
  return new Promise((resolve, reject) => {
    axios
      .get(API.UPLOADJOBSTATUS_URL, {
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("bearer_token"),
        },
      })
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR === error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}

//Post job on Dice for pow-128 CODE START... Ayush
export function postJobOnDice(Data, res) {
  return new Promise((resolve, reject) => {
    axios
      .post(API.DICEJOBPOST_URL, Data, {
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("bearer_token"),
          "content-type": "text/plain",
        },
      })
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR === error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}
//Post Job on Dice POW-128 Code Ends//

//get all dashboard Data Pow-99 starts//

export function getDashboardData(res) {
  return new Promise((resolve, reject) => {
    axios
      .get(API.GETALLDATA, {
        headers: {
          Authorization: "Bearer" + " " + localStorage.getItem("bearer_token"),
        },
      })
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR === error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}

//get all  dashboard Data Pow-99 ends//

//get all job Unattended POW_99 starts
export function getAllJobUnattended(data, res) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API.GETALLJOBUNATTENDED +
          "?startDate=" +
          data.startDate +
          "&endDate=" +
          data.endDate,
        {
          headers: {
            Authorization:
              "Bearer" + " " + localStorage.getItem("bearer_token"),
          },
        }
      )
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR === error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}
//get all job Unattended POW_99 ends

//get all job clients total POW_99 starts
export function getAllJobsClientsTotal(data, res) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API.GETALLJOBSCLIENTTOTALS +
          "?startDate=" +
          data.startDate +
          "&endDate=" +
          data.endDate,
        {
          headers: {
            Authorization:
              "Bearer" + " " + localStorage.getItem("bearer_token"),
          },
        }
      )
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR === error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}

//get all job clients total POW_99 starts

//get Recruiter Submission POW_99 starts

export function getRecruiterSubmissions(data, res) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API.GETRECRUITERSUBMISSIONS +
          "?startDate=" +
          data.startDate +
          "&endDate=" +
          data.endDate,
        {
          headers: {
            Authorization:
              "Bearer" + " " + localStorage.getItem("bearer_token"),
          },
        }
      )
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR === error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}

//get Recruiter Submission POW_99 ends

//get assigned recruiters jobs POW_99 starts

export function getAssignedRecruitersJobs(data, res) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API.ASSIGNEDRECRUITERSJOBS +
          "?startDate=" +
          data.startDate +
          "&endDate=" +
          data.endDate,
        {
          headers: {
            Authorization:
              "Bearer" + " " + localStorage.getItem("bearer_token"),
          },
        }
      )
      .then(
        (response) => {
          if (APP.SUCCESS === response.status) {
            res(response.data);
          }
        },
        (error) => {
          if (APP.LOGOUTERROR === error.response.status) {
            Logoutsession();
            toast("Your session is timed out, not able to proceed further", {
              position: toast.POSITION.TOP_CENTER,
              className: "foo-bar",
            });
          } else if (APP.TOKENERROR === error.response.data.erroCode) {
            refreshTokenApi(error.response.data.refreshToken, (resp) => {
              localStorage.setItem("bearer_token", resp.token);
              res(resp);
            });
          } else {
            res(error.response.data);
          }
        }
      );
  });
}
//get assigned recruiters jobs POW_99 ends

/* Added this code for  POW-486. Dharmateja. February 24 2023.. Code Start */
export function manageCandidateStatusAPI(res) {
  return new Promise((resolve, reject) => {
      axios.get(API.MANAGECANDIDATESTATUS_URL,
          {
              headers: {
                  'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
              }
          }
      )
          .then(response => {
              if (APP.SUCCESS === response.status) {
                  res(response.data)
              }
          },
              (error) => {
                  if (APP.LOGOUTERROR === error.response.status) {
                      Logoutsession()
                      toast("Your session is timed out, not able to proceed further", {
                          position: toast.POSITION.TOP_CENTER,
                          className: 'foo-bar'
                      })
                  }
                  else if (APP.TOKENERROR === error.response.data.erroCode) {
                      refreshTokenApi(error.response.data.refreshToken, (resp) => {
                          localStorage.setItem('bearer_token', resp.token)
                          res(resp)
                      })
                  }
                  else {
                      res(error.response.data)
                  }
              }
          );
  });
}
/* Dharmateja. February 24 2023.. Code End */


