import _ from '@lodash';
import { useCallback, useState } from 'react';

function useForm(initialState, onSubmit) {
	const [form, setForm] = useState(initialState);

	const handleChange = useCallback(event => {
		event.persist();
		setForm(_form =>
			_.setIn(
				{ ..._form },
				event.target.name,
				event.target.type === 'checkbox' ? event.target.checked : event.target.value
			)
		);
	}, []);

	//Changes the description in the send email dialog
	const handleDescriptionChangeInEditor = useCallback(model => {
		setForm(_form =>
			_.setIn(
				{ ..._form },
				'DescriptionMail',
				model
			)
		);
	}, []);

	//For adding email ids in add/edit recruiters' network modal
	const handleAddEmailChipInRecruitersNetworkAddEditModal = (chip) => {
        form.clientstate.push(chip);
		setForm(_form =>
			_.setIn(
				{ ..._form },
				'clientstate',
				form.clientstate
			)
		);
	}
	
	//For adding new chip item in the array
	const handleAddChip = (chip, hookitem) => {
        form[hookitem].push(chip);
		setForm(_form =>
			_.setIn(
				{ ..._form },
				hookitem,
				form[hookitem]
			)
		);
    }

	//For deleting a chip item at a particular index in an existing array
	const handleDeleteChip = (chip, index, hookitem) => {
        form[hookitem].splice(index, 1);
		setForm(_form =>
			_.setIn(
				{ ..._form },
				hookitem,
				form[hookitem]
			)
		);
	}
	
	//For deleting email id from email list in add/edit recruiters' network modal
	const handleDeleteEmailChipInRecruitersNetworkAddEditModal = (chip, index) => {
        form.clientstate.splice(index, 1);
		setForm(_form =>
			_.setIn(
				{ ..._form },
				'clientstate',
				form.clientstate
			)
		);
    }

	const resetForm = useCallback(() => {
		if (!_.isEqual(initialState, form)) {
			console.log('Initial State -> ',initialState)
			setForm(initialState);
		}
		Object.keys(form).forEach(value=>{
			form[value] = ''
		})
		// console.log('FFFFFFFORMMMMM -> ',form)
	}, [form, initialState]);

	const setInForm = useCallback((name, value) => {
		setForm(_form => _.setIn(_form, name, value));
	}, []);

	const handleSubmit = useCallback(
		event => {
			if (event) {
				event.preventDefault();
			}
			if (onSubmit) {
				onSubmit();
			}
		},
		[onSubmit]
	);

	return {
		form,
		handleChange,
		handleSubmit,
		resetForm,
		setForm,
		setInForm,
		handleDescriptionChangeInEditor,
		handleAddEmailChipInRecruitersNetworkAddEditModal,
		handleDeleteEmailChipInRecruitersNetworkAddEditModal,
		handleAddChip,
		handleDeleteChip
	};
}

export default useForm;
