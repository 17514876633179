import * as jobstatusapi from 'app/services/dashboard/jobstatus';

export function jobstatuslistActions(data,res) {
	// return dispatch =>
    jobstatusapi.jobstatusList(data,(response)=>{
		if (response.token) {
			jobstatusapi.jobstatusList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

//create 
export function jobstatusCreateActions(data,res){
	jobstatusapi.jobstatusCreate(data,(response)=>{
		if (response.token) {
			jobstatusapi.jobstatusCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit 
export function jobstatusEditActions(data,res){
	jobstatusapi.jobstatusEdit(data,(response)=>{
		if (response.token) {
			jobstatusapi.jobstatusEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// search
export function jobstatusSearchActions(data,res){
	jobstatusapi.jobstatus(data,(response)=>{
		if (response.token) {
			jobstatusapi.jobstatus(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

//change status
export function jobStatusActions(data,res) {
	jobstatusapi.jobStatusUpdate(data,(response)=>{
		if (response.token) {
			jobstatusapi.jobStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})			
}
