import * as workauthorizationapi from 'app/services/dashboard/workauthorizationservices';


export function workauthorizationlistActions(data, res) {
    // return dispatch =>
    workauthorizationapi.workAuthorizationList(data, (response) => {
        if (response.token) {
			workauthorizationapi.workAuthorizationList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
    })
    // .then(user => {
    //     console.log("businessunitlistuser", user)
    // 	// dispatch(UserActions.businessUnitListData(user));
    // 	return dispatch({
    // 		type: BUSINESSUNIT_LIST,
    // 		payload:user
    // 	});
    // })
    // .catch(error => {
    // 	console.log("error", error)
    // 	return dispatch({
    // 		type: BUSINESSUNITLIST_ERROR,
    // 		payload: error
    // 	});
    // });
}

//create 
export function workauthorizationCreateActions(data,res){
	workauthorizationapi.workauthorizationCreate(data,(response)=>{
		if (response.token) {
			workauthorizationapi.workauthorizationCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit 
export function workauthorizationEditActions(data,res){
	workauthorizationapi.workauthorizationEdit(data,(response)=>{
		if (response.token) {
			workauthorizationapi.workauthorizationEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//change status
export function workauthorizationstatusActions(data,res) {
	// console.log('statusdataaction',data)
	workauthorizationapi.workauthorizationStatusUpdate(data,(response)=>{
        if (response.token) {
			workauthorizationapi.workauthorizationStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})			
}
//search 
export function workauthorizationsearchActions(data,res) {
	// console.log('statusdataaction',data)
	workauthorizationapi.workAuthorizationList(data,(response)=>{
        if (response.token) {
			workauthorizationapi.workauthorizationStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})			
}
