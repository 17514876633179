


import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';


i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

console.log("getrole123", localStorage.getItem("Role"))

const teamleadConfig = [
    localStorage.getItem('Page') ?
        {
            id: 'dashboard',
            title: 'Dashboard',
            translate: 'Dashboard',
            type: 'collapse',
            icon: 'dashboard',
            children: [
                {
                    id: "analytics",
                    title: "Analytics",
                    type: "item",
                    url: "/dashboard/teamlead/analytics"
                },
            ]
        } : {},
    localStorage.getItem('Page') && (localStorage.getItem('Page').split(",").includes(6) || localStorage.getItem('Page').split(",").includes(7)) ? {

        id: 'manage',
        title: `Manage Client's / Vendor's`,
        translate: `Manage Client's / Vendor's`,
        type: 'collapse',
        icon: 'cloud_upload',
        children: [
            localStorage.getItem('Page').split(",").includes(6) ? {
                id: "Manageclient",
                title: "Manage client",
                type: "item",
                url: "/teamlead/manageclient"
            } : {},
            localStorage.getItem('Page').split(",").includes(7) ? {
                id: "Managevendor",
                title: "Manage Vendor",
                type: "item",
                url: "/teamlead/managevendor"
            } : {},
            /* Added this code for  POW-521. Prasanna. August 01 2023.. Code Start */	
				localStorage.getItem('Page').split(",").includes(8) ? {
					id: "ManageVMSPasswords",
					title: "Manage VMS Passwords",
					type: "item",
					url: "/teamlead/manageVMSPasswords"
				} : {},
				/* Prasanna. August 01 2023. Code End */
        ]
    } : {},


];

export default teamleadConfig;
