import * as employmenttypesapi from 'app/services/dashboard/employmenttypesservices';


export function employmenttypesListActions(data, res) {
    // return dispatch =>
    employmenttypesapi.employmenttypesList(data, (response) => {
        if (response.token) {
			employmenttypesapi.employmenttypesList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
    })
    // .then(user => {
    //     console.log("businessunitlistuser", user)
    // 	// dispatch(UserActions.businessUnitListData(user));
    // 	return dispatch({
    // 		type: BUSINESSUNIT_LIST,
    // 		payload:user
    // 	});
    // })
    // .catch(error => {
    // 	console.log("error", error)
    // 	return dispatch({
    // 		type: BUSINESSUNITLIST_ERROR,
    // 		payload: error
    // 	});
    // });
}

//create 
export function employmenttypesCreateActions(data,res){
	employmenttypesapi.employmenttypesCreate(data,(response)=>{
		if (response.token) {
			employmenttypesapi.employmenttypesCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit 
export function employmenttypesEditActions(data,res){
	employmenttypesapi.employmenttypesEdit(data,(response)=>{
		if (response.token) {
			employmenttypesapi.employmenttypesEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//change status
export function employmenttypesstatusActions(data,res) {
	employmenttypesapi.employmenttypesStatusUpdate(data,(response)=>{
        if (response.token) {
			employmenttypesapi.employmenttypesStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})			
}
//search 
export function employmenttypesNameListActions(data,res) {
	employmenttypesapi.employmenttypesnameList(data,(response)=>{
        if (response.token) {
			employmenttypesapi.employmenttypesnameList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})			
}
