import * as Actions from '../actions/dashboardactions';
const initialState = {
    data: [],
};
const businessunits = (state = initialState, action) => {
    switch (action.type) {
        case Actions.BUSINESSUNIT_LIST: 
            return {
                ...state, ...{ businessunitlistsuccess: action.payload }
            }
        
        case Actions.BUSINESSUNITLIST_ERROR: {
            return { ...state, ...{ businessunitlisterror: action.payload } }
        }
        default: {
			return state;
		}
    }
}
export default businessunits;