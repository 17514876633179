// import FuseAnimateGroup from '@fuse/core/FuseAnimateGroup';
import FuseUtils from '@fuse/utils';
import { amber } from '@material-ui/core/colors';
// import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
// import IconButton from '@material-ui/core/IconButton';
// import Input from '@material-ui/core/Input';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
// import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
// import Tooltip from '@material-ui/core/Tooltip';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import * as UserActions from 'app/auth/store/actions';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom'
import * as authActions from '../../../app/auth/store/actions/recruiteractions';

const useStyles = makeStyles({
	root: {
		'&.horizontal': {},
		'&.vertical': {
			flexDirection: 'column'
		}
	},
	item: {
		textDecoration: 'none!important',
		color: 'inherit'
	},
	addIcon: {
		color: amber[600]
	}
});

function FuseShortcuts(props) {
	// const dispatch = useDispatch();
	let location = useLocation();
	// const history = useHistory()
	let getprevlocation = document.referrer
	// const shortcuts = useSelector(({ auth }) => auth.user.data.shortcuts);
	const navigationData = useSelector(({ fuse }) => fuse.navigation);

	const classes = useStyles(props);
	const searchInputRef = useRef(null);
	const [addMenu, setAddMenu] = useState(null);
	const [searchText, setSearchText] = useState('');
	const [searchResults, setSearchResults] = useState(null);
	const [navigation, setNavigation] = useState(null);

	// const shortcutItems = shortcuts ? shortcuts.map(id => FuseUtils.findById(navigationData, id)) : [];
	//go back to upload job from upload job details
	// const goback = () => {
	// 	dispatch({
	// 		type: authActions.JOBDETAILS_ERROR,
	// 		payload: []
	// 	});
	// 	dispatch({
	// 		type: authActions.CANDIDATESEARCHLIST_ERROR,
	// 		payload: []
	// 	});
	// 	dispatch({
	// 		type: authActions.RUNMATCH_ERROR,
	// 		payload: []
	// 	});
	// 	history.push("/upload/uploadjobs")
	// }
	// const gobacktomatch = () => {
	// 	dispatch({
	// 		type: authActions.RUNMATCH_ERROR,
	// 		payload: []
	// 	});
	// 	history.push("/upload/matchhistory")
	// }
	// const gobacktomanage = () => {
	// 	history.push("/upload/managecandidates")
	// }

	useEffect(() => {
		function flattenNavigation() {
			setNavigation(FuseUtils.getFlatNavigation(navigationData));
		}
		flattenNavigation();
	}, [props.location, navigationData]);

	// function addMenuClick(event) {
	// 	setAddMenu(event.currentTarget);
	// }

	// function addMenuClose() {
	// 	setAddMenu(null);
	// }

	// function search(ev) {
	// 	const newSearchText = ev.target.value;

	// 	setSearchText(newSearchText);

	// 	if (newSearchText.length !== 0 && navigation) {
	// 		setSearchResults(navigation.filter(item => item.title.toLowerCase().includes(newSearchText.toLowerCase())));
	// 		return;
	// 	}
	// 	setSearchResults(null);
	// }

	// function toggleInShortcuts(id) {
	// 	let newShortcuts = [...shortcuts];
	// 	newShortcuts = newShortcuts.includes(id) ? newShortcuts.filter(_id => id !== _id) : [...newShortcuts, id];
	// 	dispatch(UserActions.updateUserShortcuts(newShortcuts));
	// }

	// function ShortcutMenuItem({ item, onToggle }) {
	// 	return (
	// 		<Link to={item.url} className={classes.item} role="button">
	// 			<MenuItem key={item.id}>
	// 				<ListItemIcon className="min-w-40">
	// 					{item.icon ? (
	// 						<Icon>{item.icon}</Icon>
	// 					) : (
	// 							<span className="text-20 font-bold uppercase text-center">{item.title[0]}</span>
	// 						)}
	// 				</ListItemIcon>
	// 				<ListItemText primary={item.title} />
	// 				<IconButton
	// 					onClick={ev => {
	// 						ev.preventDefault();
	// 						ev.stopPropagation();
	// 						onToggle(item.id);
	// 					}}
	// 				>
	// 					<Icon color="action">{shortcuts.includes(item.id) ? 'star' : 'star_border'}</Icon>
	// 				</IconButton>
	// 			</MenuItem>
	// 		</Link>
	// 	);
	// }

	return (
		<div className={clsx(classes.root, props.variant, 'flex flex-1', props.variant === 'vertical' && 'flex-grow-0 flex-shrink', props.className)}>
			<div className="content-header">
				<p style={{ width: "", paddingLeft: "10px", fontSize: '13px', color: '#aaa' }}><b>
					{location.pathname.includes("/upload/uploadjobs") ? <div> <span>Jobs / </span><span style={{ color: '#384AAC ' }}>Upload Jobs</span></div> : location.pathname.includes("/upload/uploadjobdetails") ? <div> <span>Upload / </span><span style={{ color: '#384AAC ' }}>Job Details</span></div> :
						location.pathname.includes("/upload/candidatesearch") ? <div> <span>Upload / </span><span style={{ color: '#384AAC ' }}>Candidate Search Results</span></div> : location.pathname.includes("/upload/matchingdetails") ? <div> {localStorage.getItem("matchhistory") === "true" ? <span>Match History / </span> : <span>Upload / </span>} <span style={{ color: '#384AAC ' }}>Match Results</span></div>
							: location.pathname.includes("/upload/managecandidates") ? <div> <span>Manage / </span><span style={{ color: '#384AAC ' }}>Manage Candidates</span></div> : location.pathname.includes("/upload/managejob") ? <div> <span>Jobs / </span><span style={{ color: '#384AAC ' }}>Manage Jobs</span></div>
								: location.pathname.includes("/upload/jobdetails") ? <div> <span>Upload / </span><span style={{ color: '#384AAC ' }}>Job Details</span></div> : location.pathname.includes("/upload/candidatedetails") ? <div> <span>Upload / </span><span style={{ color: '#384AAC ' }}>Candidate Details</span></div>
									: location.pathname.includes("/upload/chatbothistory") ? <div> <span>History / </span><span style={{ color: '#384AAC ' }}>Chat Conversation History</span></div> : location.pathname.includes("/dashboard/analytics") ? <div> <span>Dashboard / </span><span style={{ color: '#384AAC ' }}>Analytics</span></div>
										: location.pathname.includes("/upload/manageconsultant") ? <div> <span>Manage / </span><span style={{ color: '#384AAC ' }}>Manage Consultants </span></div> : location.pathname.includes("/upload/matchhistory") ? <div> <span>History / </span><span style={{ color: '#384AAC ' }}>Match History</span></div>

											// teamlead
											: location.pathname.includes("/teamlead/manageclient") ? <div> <span>Manage / </span><span style={{ color: '#384AAC ' }}>Manage Client</span></div>
												: location.pathname.includes("/teamlead/managevendor") ? <div> <span>Manage / </span><span style={{ color: '#384AAC ' }}>Manage Vendor</span></div>
												/* Added this code for  POW-521. Prasanna. August 01 2023.. Code Start */	
													:location.pathname.includes("/teamlead/manageVMSPasswords") ? <div> <span>Manage / </span><span style={{ color: '#384AAC ' }}>Manage VMS Passwords</span></div>
												/* Prasanna. August 01 2023. Code End */
													: location.pathname.includes("/teamlead/managerecruitersnetwork") ? <div> <span>Manage / </span><span style={{ color: '#384AAC ' }}>Manage Recruiters' Network</span></div>
														: location.pathname.includes("/teamlead/dashboard") ? <div> <span>Home / </span><span style={{ color: '#384AAC ' }}>About</span></div>
														// admin
															// Commenting the following code for POW-102. Khushi. April 10 2023.. Code Start /
														    //: location.pathname.includes("/dashboard") ? <div> <span>Home / </span><span style={{ color: '#384AAC ' }}>About</span></div> : location.pathname.includes("/organizationalsetup/businessunit") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Business Units</span></div>
															// Comment end. Khushi.  April 10 2023. /

															// Added this code for  POW-102. Khushi. April 10 2023.. Code Start /
															: location.pathname.includes("/recruiter/dashboardBackgroundprocess") ? <div> <span>Home / </span><span style={{ color: '#384AAC ' }}>Automation Status</span></div>: location.pathname.includes("/organizationalsetup/businessunit") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Business Units</span></div>
															: location.pathname.includes("/recruiter/dashboard") ? <div> <span>Home / </span><span style={{ color: '#384AAC ' }}>About</span></div> : location.pathname.includes("/organizationalsetup/businessunit") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Business Units</span></div>
															: location.pathname.includes("/automationdashboard") ? <div> <span>Home / </span><span style={{ color: '#384AAC ' }}>Automation Dashboard</span></div>: location.pathname.includes("/organizationalsetup/businessunit") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Business Units</span></div>
															// Khushi. April 10 2023. Code End /
															: location.pathname.includes("/analyticsdashboard") ? <div> <span>Home / </span><span style={{ color: '#384AAC ' }}>Dashboard</span></div>		: location.pathname.includes("/organizationalsetup/clientjobstatus") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Client Job Status</span></div> : 
																location.pathname.includes("/organizationalsetup/jobstatus") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Job Status</span></div> : 
																// Added this code for  POW-20. Prasanna. August 25 2022.. Code Start 
																location.pathname.includes("/organizationalsetup/jobstatus") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Manager Details</span></div> :
																// Prasanna. August 25 2022. Code End
																location.pathname.includes("/organizationalsetup/candidatestatus") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Candidate Status </span></div>
																	: location.pathname.includes("/organizationalsetup/workauthorization") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Work Authorization</span></div> 
																	: location.pathname.includes("/organizationalsetup/sourcenames") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Source Types</span></div>
																		: location.pathname.includes("/organizationalsetup/chatbottype") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Chatbot Types</span></div>
																		: location.pathname.includes("/organizationalsetup/employmenttype") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Employment Types</span></div>
																		: location.pathname.includes("/organizationalsetup/setupexpenses") ? <div> <span>OrganizationalSetup / </span><span style={{ color: '#384AAC ' }}>Setup Expenses</span></div>
																			: location.pathname.includes("/securitysetup/managesecurityuser") ? <div> <span>Security Setup / </span><span style={{ color: '#384AAC ' }}>Manage Security User</span></div>
																				: location.pathname.includes("/securitysetup/managesecurityrole") ? <div> <span>Security Setup / </span><span style={{ color: '#384AAC ' }}>Manage Security Role</span></div>
																				 //Added this code. Anand_POW-492. June 12 2023.. Code Start /
																				    : location.pathname.includes("/securitysetup/addrole") ? <div> <span>Security Setup / </span><span style={{ color: '#384AAC ' }}>Manage Security Role</span></div>
																						: location.pathname.includes("/securitysetup/adduser") ? <div> <span>Security Setup / </span><span style={{ color: '#384AAC ' }}> Manage Security User</span></div> : ''}</b></p>




				{/*  {
				window.location.href.includes("/upload/matchingdetails?jobId=") ?
				<Button variant="contained" variant="contained" color="primary" style={{ width: "%",position:'relative',left:"50px" }} onClick={gobacktomatch} >Go to match history</Button>
					:
					// location.pathname.includes("/upload/candidatedetails") ?
					// 	<Button variant="contained" variant="contained" color="primary" style={{ width: "30%" }} onClick={gobacktomanage} >Go to Manage Candidate</Button>
					// 	:
					// 	location.pathname.includes("/upload/uploadjobdetails") || location.pathname.includes("/upload/candidatesearch")
					// 		|| location.pathname.includes("/upload/matchingdetails") ?
					// 		<Button variant="contained" variant="contained" color="primary" style={{ width: "20%" }} onClick={goback} >Go to upload job</Button>
					// 		:
				// 			''
				// } */}
			</div>


			{/* <FuseAnimateGroup
				enter={{
					animation: 'transition.expandIn'
				}}
				className={clsx('flex flex-1', props.variant === 'vertical' && 'flex-col')}
			> */}
			{/* {shortcutItems.map(
					item =>
						item && (
							<Link to={item.url} key={item.id} className={classes.item} role="button">
								<Tooltip
									title={item.title}
									placement={props.variant === 'horizontal' ? 'bottom' : 'left'}
								>
									<IconButton className="w-40 h-40 p-0">
										{item.icon ? (
											<Icon>{item.icon}</Icon>
										) : (
											<span className="text-20 font-bold uppercase">{item.title[0]}</span>
										)}
									</IconButton>
								</Tooltip>
							</Link>
						)
				)} */}

			{/* <Tooltip
					title="Click to add/remove shortcut"
					placement={props.variant === 'horizontal' ? 'bottom' : 'left'}
				>
					<IconButton
						className="w-40 h-40 p-0"
						aria-owns={addMenu ? 'add-menu' : null}
						aria-haspopup="true"
						onClick={addMenuClick}
					>
						<Icon className={classes.addIcon}>star</Icon>
					</IconButton>
				</Tooltip> */}
			{/* </FuseAnimateGroup> */}

			{/* <Menu
				id="add-menu"
				anchorEl={addMenu}
				open={Boolean(addMenu)}
				onClose={addMenuClose}
				classes={{
					paper: 'mt-48'
				}}
				onEntered={() => {
					searchInputRef.current.focus();
				}}
				onExited={() => {
					setSearchText('');
				}}
			> */}
			{/* <div className="p-16 pt-8">
					<Input
						inputRef={searchInputRef}
						value={searchText}
						onChange={search}
						placeholder="Search for an app or page"
						className=""
						fullWidth
						inputProps={{
							'aria-label': 'Search'
						}}
					/>
				</div> */}

			{/* <Divider /> */}

			{/* {searchText.length !== 0 &&
					searchResults &&
					searchResults.map(item => (
						<ShortcutMenuItem key={item.id} item={item} onToggle={() => toggleInShortcuts(item.id)} />
					))}

				{searchText.length !== 0 && searchResults.length === 0 && (
					<Typography color="textSecondary" className="p-16 pb-8">
						No results..
					</Typography>
				)} */}

			{/* {searchText.length === 0 &&
					shortcutItems.map(
						item =>
							item && (
								<ShortcutMenuItem
									key={item.id}
									item={item}
									onToggle={() => toggleInShortcuts(item.id)}
								/>
							)
					)} */}
			{/* </Menu> */}
		</div>
	);
}

FuseShortcuts.propTypes = {};
FuseShortcuts.defaultProps = {
	variant: 'horizontal'
};

export default React.memo(FuseShortcuts);
