import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
// import { useDispatch, useSelector } from 'react-redux';
// import React, { useEffect, useRef, useState } from 'react';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);
const getAccess = (key) => {

}
// Added this code for  POW-492. Anand. July 10 2023.. Code Start /
const navigationConfig =  localStorage.getItem('Page') ? [
	localStorage.getItem('Page').split(",").includes("23") || localStorage.getItem('Page').split(",").includes("24") || localStorage.getItem('Page').split(",").includes("25") ? {
		id: 'dashboard',
		title: 'Home',
		translate: 'Home',
		type: 'collapse',
		icon: 'dashboard',
		children: [
			localStorage.getItem('Page').split(",").includes("23") ? {
				id: "analytics",
				// title: "About",
				title: "Dashboard",
				type: "item",
				// url: "/dashboard",
				url: "/analyticsdashboard",
				role: getAccess('dashboard'),
			} : localStorage.getItem('Page').split(",").includes("24") ? {
				id: "analytics",
				title: "Dashboard",
				type: "item",
				url: "/recruiter/dashboard",
				role: getAccess('dashboard'),
			} : localStorage.getItem('Page').split(",").includes("25") ? {
				id: "analyticss",
				title: "Dashboard",
				type: "item",
				url: "/teamlead/dashboard",
				role: getAccess('dashboard'),
			} : {},
		]
	} : {},
// Added this code for  POW-492. Anand. July 10 2023.. Code End /
	localStorage.getItem('Page').split(",").includes("4") || localStorage.getItem('Page').split(",").includes("5")
		|| localStorage.getItem('Page').split(",").includes("9") || localStorage.getItem('Page').split(",").includes("10") ||
		localStorage.getItem('Page').split(",").includes("11") || localStorage.getItem('Page').split(",").includes("12") ?
		{
			id: 'organizationalsetup',
			title: 'Organizational Setup',
			translate: 'Organizational Setup',
			type: 'collapse',
			icon: 'business',
			children: [
				localStorage.getItem('Page').split(",").includes("4") ?
					{
						id: "businessunits",
						title: "Business Units",
						type: "item",
						url: "/organizationalsetup/businessunit",
						role: getAccess('businessunits'),
					} :
					localStorage.getItem('Page').split(",").includes("5") ?
						{
							id: "clientjobstatus",
							title: "Client Job Status",
							type: "item",
							url: "/organizationalsetup/clientjobstatus",
							role: getAccess('clientjobstatus'),
						} :						
						localStorage.getItem('Page').split(",").includes("9") ?
							{
								id: "candidatejobstatus",
								title: "Candidate Status",
								type: "item",
								url: "/organizationalsetup/candidatestatus",
								role: getAccess('candidatejobstatus'),
							} :
							// Added this code for  POW-20. Prasanna. August 25 2022.. Code Start
							localStorage.getItem('Page').split(",").includes("9") ?
								{
									id: "managerdetails",
									title: "Manager Details",
									type: "item",
									url: "/organizationalsetup/managerdetails",
									role: getAccess('managerdetails'),
								} :
								// Prasanna. August 25 2022. Code End
							localStorage.getItem('Page').split(",").includes(10) ?
								{
									id: "workauthorization",
									title: "Work Authorization",
									type: "item",
									url: "/organizationalsetup/workauthorization",
									role: getAccess('workauthorization'),
								} :
								localStorage.getItem('Page').split(",").includes("11") ?
									{
										id: "sourcenames",
										title: "Source Types",
										type: "item",
										url: "/organizationalsetup/sourcenames",
										role: getAccess('sourcenames'),
									} :
									localStorage.getItem('Page').split(",").includes("5") ?
										{
											id: "jobstatus",
											title: "Job Status",
											type: "item",
											url: "/organizationalsetup/jobstatus",
											role: getAccess('jobstatus'),
										} :
										localStorage.getItem('Page').split(",").includes("5") ?
										{
											id: "setupexpenses",
											title: "Setup Expenses",
											type: "item",
											url: "/organizationalsetup/setupexpenses",
											role: getAccess('setupexpenses'),
										}:
									localStorage.getItem('Page').split(",").includes("12") ?
										{
											id: "chatbottypes",
											title: "Chatbot Types",
											type: "item",
											url: "/organizationalsetup/chatbottype",
											role: getAccess('chatbottypes'),
										} : {},
			]
			// url: '/example'
		} : {},
	localStorage.getItem('Page') && (localStorage.getItem('Page').split(",").includes("13") ||
		localStorage.getItem('Page').split(",").includes("14")) ? {
			id: 'securitysetup',
			title: 'Security setup',
			translate: 'Security setup',
			type: 'collapse',
			icon: 'lock',
			children: [
				localStorage.getItem('Page').split(",").includes("13") ?
					{
						id: "managesecurityuser",
						title: "Manage Security User",
						type: "item",
						url: "/securitysetup/managesecurityuser",
						role: getAccess('managesecurityuser'),
					} : {},
				localStorage.getItem('Page').split(",").includes("14") ?
					{
						id: "managesecurityrole",
						title: "Manage Security Role",
						type: "item",
						url: "/securitysetup/managesecurityrole",
						role: getAccess('managesecurityrole'),
					} : {},
				localStorage.getItem('Page').split(",").includes("14") ?
					{
						id: "managesecurityaddrole",
						title: "Manage Security Add Role",
						type: "item",
						url: "/securitysetup/addrole",
						role: getAccess('managesecurityaddrole'),
					} : {},
					localStorage.getItem('Page').split(",").includes("14") ?
					{
						id: "managesecurityadduser",
						title: "Manage Security Add User",
						type: "item",
						url: "/securitysetup/adduser",
						role: getAccess('managesecurityaddrole'),
					} : {},	
			]
		} : {},

	localStorage.getItem('Page') && (localStorage.getItem('Page').split(",").includes("1") ||
		localStorage.getItem('Page').split(",").includes("2")) ? {
			id: 'Jobs',
			title: 'Jobs',
			translate: 'Jobs',
			type: 'collapse',
			icon: 'cloud_upload',
			children: [
				localStorage.getItem('Page').split(",").includes("1") ? {
					id: "Upload job",
					title: "Upload job",
					type: "item",
					url: "/upload/uploadjobs"
				} : {},
				localStorage.getItem('Page').split(",").includes("2") ? {
					id: "Manage Jobs",
					title: "Manage Jobs",
					type: "item",
					url: "/upload/managejob"
				} : {},
			]
		} : {},

	localStorage.getItem('Page') && (localStorage.getItem('Page').split(",").includes("3") ||
		localStorage.getItem('Page').split(",").includes("15")) ? {
			id: 'Candidates',
			title: `Manage Candidate / Consultant`,
			translate: `Manage Candidate / Consultant`,
			type: 'collapse',
			icon: 'supervisor_account',
			children: [
				localStorage.getItem('Page').split(",").includes("3") ? {
					id: "Manage Candidates",
					title: "Manage Candidates",
					type: "item",
					url: "/upload/managecandidates"
				} : {},
				localStorage.getItem('Page').includes("15") ? {
					id: "Manage Consultant",
					title: "Manage Consultant",
					type: "item",
					url: "/upload/manageconsultant"
				} : {},
			]
		} : {},

	localStorage.getItem('Page') && (localStorage.getItem('Page').split(",").includes("16") ||
		localStorage.getItem('Page').split(",").includes("17")) ? {
			id: 'History',
			title: 'History',
			translate: 'History',
			type: 'collapse',
			icon: 'history',
			children: [
				localStorage.getItem('Page').split(",").includes("16") ? {
					id: "Match history",
					title: "Match history",
					type: "item",
					url: "/upload/matchhistory"
				} : {},
				localStorage.getItem('Page').split(",").includes("17") ? {
					id: "ChatBot history",
					title: "ChatBot history",
					type: "item",
					url: "/upload/chatbothistory"
				} : {},
			]
		} : {},

	localStorage.getItem('Page') && (localStorage.getItem('Page').split(",").includes("6") ||
		localStorage.getItem('Page').split(",").includes("7") ||
		/* Added this code for  POW-521. Prasanna. August 01 2023.. Code Start */	
		localStorage.getItem('Page').split(",").includes("8")||
		/* Prasanna. August 01 2023. Code End */
		 localStorage.getItem('Page').split(",").includes("18")) ? {
			id: 'manage',
			title: `Manage Client's / Vendor's / Recruiter's Network`,
			translate: `Manage Client's / Vendor's / Recruiter's Network`,
			type: 'collapse',
			icon: 'cloud_upload',
			children: [
				localStorage.getItem('Page').split(",").includes("6") ? {
					id: "Manageclient",
					title: "Manage Client",
					type: "item",
					url: "/teamlead/manageclient"
				} : {},
				localStorage.getItem('Page').split(",").includes("7") ? {
					id: "Managevendor",
					title: "Manage Vendor",
					type: "item",
					url: "/teamlead/managevendor"
				} : {},
				/* Added this code for  POW-521. Prasanna. August 01 2023.. Code Start */	
				localStorage.getItem('Page').split(",").includes("8") ? {
					id: "ManageVMSPasswords",
					title: "Manage VMS Passwords",
					type: "item",
					url: "/teamlead/manageVMSPasswords"
				} : {},
				/* Prasanna. August 01 2023. Code End */
				localStorage.getItem('Page').split(",").includes("18") ? {
					id: "Managerecruitersnetwork",
					title: "Manage Recruiters' Network",
					type: "item",
					url: "/teamlead/managerecruitersnetwork"
				} : {},
			]
		} : {},
	// ]
	// } : {}
] : []
// const PageAssign = useSelector(({ fuse }) => fuse);

export default navigationConfig;
