import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import InputLabel from '@material-ui/core/InputLabel';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
// import * as authActions from 'app/auth/store/actions/dashboardactions';
// import * as APP from 'app/constants/AppConstants'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import ListItemLink from '@material-ui/core/ListItemLink';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
    modal: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // marginTop: '-400px'
        width: "30%",
        marginLeft: "32%",
        marginTop: "20px",
        alignItems: "center",
        justifyContent: "center"
    },
    // @media only screen and (max-width: 1025px) {
    // }
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        // boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));


export default function TransitionsModal(props) {
    const [value, setValue] = useState([]);  
    const [valueComp, setValueComp]=useState([])  
    const [bidValue, setBidValue] = React.useState();

    const classes = useStyles();
    const { form, handleChange } = useForm({
        businessunit: !localStorage.getItem("buid") ? '' : localStorage.getItem("buid")
    });
    useEffect(() => {
        value.splice(0, value.length)

        var getDataArray=[];
        var nameArr = form.businessunit.split(',');
        var activatedBUs = "";
        for(var i=0; i<nameArr.length;i++)
        {
            console.log("New Value",nameArr[i])
            props.getBusinessListData.filter(item=> item.bid===nameArr[i]).map(fName => (
                    getDataArray.push(fName)
                
            ))
            setValue(getDataArray)
            // activatedBUs += nameArr[i] + ",";
            activatedBUs += nameArr[i] + ",";
        }
        localStorage.setItem("activatedBUs", activatedBUs)
    }, [props.getBusinessListData])

    function handleSubmit(ev) {
        var id=[];
        ev.preventDefault();
        console.log("dtata", props.getBusinessListData)
        for(var i=0;i<value.length;i++)
        {
            console.log("value Buss", value[i].bid)
            id.push(value[i].bid)
        }

        props.sumbitbusiness(id.toString())
        localStorage.setItem("buid", id.toString())
    }
    const businessp = { fontWeight: "400", fontFamily: 'Muli,Roboto,"Helvetica",Arial,sans-serif', fontSize: "1.4rem", borderBottom: "1px solid gray", marginTop: "11px" }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>
                    <div className={classes.paper}>
                        <h3
                            style={{ fontSize: "16px", textAlign: "center", fontWeight: "bold", color: "#293B96" }}
                        >
                            Business Unit
                        </h3>
                        <form
                            name="recoverForm"
                            noValidate
                            className="flex flex-col justify-center w-full"
                            onSubmit={handleSubmit}
                        >
                            <div style={{ borderBottom: '0.1px solid grey', marginTop: '10px' }}></div>
                            <div style={{ marginTop: '25px' }}>
                                {/* <InputLabel id="demo-mutiple-checkbox-label">{localStorage.getItem("Role") === "1" ? "Select BU" : "BU"}</InputLabel> */}
                                
                                <Autocomplete
                                    multiple
                                    id="combo-box-demo"
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }} 
                                    filterSelectedOptions={true}
                                    getOptionSelected={(value, item) => item.code === value.code}
                                    options={props.getBusinessListData}
                                    getOptionLabel={(option) => option.code}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.code}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" fullWidth label="Select Business Unit" />
                                    )}
                                />
                                {/* {localStorage.getItem("Role") === "1" ?
                                    <Select
                                        labelId="demo-mutiple-checkbox-label"
                                        id="demo-mutiple-checkbox"
                                        name="businessunit"
                                        multiple
                                        style={{ width: '100%' }}
                                        value={form.businessunit}
                                        onChange={handleChange}
                                        input={<Input />}
                                    // renderValue={(selected) => selected.join(', ')}

                                    >
                                        <MenuItem value='' disabled>
                                            <em>Select Business unit name</em>
                                        </MenuItem>
                                        {props.getBusinessListData && props.getBusinessListData.map(row => (
                                            row.status === "Y" ?
                                                <MenuItem key={row.bid} value={row.code}>
                                                    <Checkbox checked={row.bid} />
                                                    <ListItemText primary={row.code} />
                                                </MenuItem>
                                                : ''

                                        ))}
                                    </Select>
                                    :
                                    <p style={businessp}>{props.getBusinessListData.length > 0 ? props.getBusinessListData.filter(e => e.bid === form.businessunit)[0].code : ""} </p>
                                } */}
                            </div>
                            <Button variant="contained" color="primary" type="submit" aria-label="Reset"
                                style={{ marginTop: "20px" }}
                                disabled={form.businessunit === '' ? true : false}
                            >
                                Submit
                            </Button>
                        </form>

                    </div>
                </Fade>
            </Modal>
        </div>
    )
}