import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
import * as APP from 'app/constants/AppConstants'
import Logoutsession from '../../logout/logoutsession.js'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshTokenApi from '../../refreshtoken/refreshtokenapi';

export function chatBotList(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.CHATBOTLIST_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}

export function chatbotCreate(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.CHATBOTCREATE_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}

export function chatbotEdit(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.CHATBOTEDIT_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//edit business unit
export function chatBotEdit(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.CHATBOTEDIT_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//update status
export function chatbotStatusUpdate(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.CHATBOTSTATUS_URL + "?status=" + data.status + "&id=" + data.id, {
            headers: {
                'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
            }
        }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data);
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.status) {
                        refreshTokenApi()
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}