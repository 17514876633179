import React from 'react';

const ManageSecurityUserConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/securitysetup/managesecurityuser',
			component:React.lazy(() => import('./ManageSecurityUser')) 
        },
	 //Added this code. Anand_POW-492. July 01 2023.. Code Start /

		{
			path: '/securitysetup/adduser',
			component:React.lazy(() => import('./tabs/NewModal')) ,
		}
	 //Added this code. Anand_POW-492. July 01 2023.. Code End /
	
	]
};

export default ManageSecurityUserConfig;