import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
// import * as APP from 'app/constants/AppConstants'

export default function refreshTokenApi(refreshtoken,res) {
    return new Promise((resolve, reject) => {
        axios.get(API.REFRESHTOKEN_URL + "?token=" + refreshtoken)
            .then(response => {
               res(response.data)
            });
    });
}