import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
import * as APP from 'app/constants/AppConstants'
import Logoutsession from '../../logout/logoutsession.js'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshTokenApi from '../../refreshtoken/refreshtokenapi'


export function functionsList(data, res) {
    return new Promise((resolve, reject) => { 
        axios.get(API.FUNCTIONS_GET_URL, {
            headers: {
                'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
            }
        }
        )
        .then(response => { 
            if(APP.SUCCESS  === response.status) {
                res(response)
            }
        }, 
        (error) => {
            if (APP.LOGOUTERROR === error.response.status) {
                toast("Your session is timed out, not able to proceed further", {
                    position: toast.POSITION.TOP_CENTER,
                    className: 'foo-bar'
                })
                Logoutsession()
            }
            else if (APP.TOKENERROR === error.response.data.erroCode) {
                refreshTokenApi(error.response.data.refreshToken, (resp) => {
                    localStorage.setItem('bearer_token', resp.token)
                    res(resp)
                })
            }
            else {
                res(error.response.data)
            }
        }
        )

    })
};