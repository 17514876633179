
import * as uploadjobapi from 'app/services/recruiterapi/uploadjob';
// import * as uploadjobdetailsapi from 'app/services/recruiterapi/uploadjobdetails';

export const JOBDETAILS_ERROR = 'JOBDETAILS_ERROR';
export const JOBDETAILS_SUCCESS = 'JOBDETAILS_SUCCESS';
export const RUNMATCH_ERROR = "RUNMATCH_ERROR"
export const RUNMATCH_SUCCESS = "RUNMATCH_SUCCESS"



//upload job file upload
export function uploadfileActions(formData, bool) {
	return dispatch =>
		uploadjobapi.uploadfile(formData, bool)
			.then(response => {
				return dispatch({
					type: JOBDETAILS_SUCCESS,
					payload: response
				});
			})
			.catch(error => {
				return dispatch({
					type: JOBDETAILS_ERROR,
					payload: error
				});
			});
}

// Upload job without reducer
export function uploadfileActionsNo(formData, res) {
	uploadjobapi.uploadfileNo(formData, (response) => {
		if (response.token) {
			uploadjobapi.uploadfileNo(formData, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}


//match history
export function matchHistoryActions(data, res) {
	uploadjobapi.matchhistory(data, (response) => {
		if (response.token) {
			uploadjobapi.matchhistory(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//go to match results
export function GoToMatchResultsActions(data, res) {
	return dispatch =>
		uploadjobapi.gotomatchresults(data)
			.then(response => {
				return dispatch({
					type: RUNMATCH_SUCCESS,
					payload: response
				})
			})
			.catch(error => {
				return dispatch({
					type: RUNMATCH_ERROR,
					payload: error
				})
			});
}

//chatbot history
export function chatbotHistoryActions(data, res) {
	uploadjobapi.chatbothistory(data, (response) => {
		if (response.token) {
			uploadjobapi.chatbothistory(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//view chatbot convo
export function viewChatbotActions(data, res) {
	uploadjobapi.viewchatbot(data, (response) => {
		if (response.token) {
			uploadjobapi.viewchatbot(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

//botListActions
export function botListActions(res) {
	uploadjobapi.botlist((response) => {
		if (response.token) {
			uploadjobapi.botlist((response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
export function graberListActions(data, res) {
	uploadjobapi.graberList(data, (response) => {
		if (response.token) {
			uploadjobapi.graberList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

/* Added this code for  POW-431. Prasanna. November 23 2022.. Code Start */
export function uploadHistoryListActions(data, res) {
	uploadjobapi.uploadHistoryList(data, (response) => {
		if (response.token) {
			uploadjobapi.uploadHistoryList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
/* Prasanna. November 23 2022. Code End */

export function graberSendActions(data, res) {
	uploadjobapi.graberSend(data, (response) => {
		if (response.token) {
			uploadjobapi.graberSend(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

