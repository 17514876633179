import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
import * as APP from 'app/constants/AppConstants'
import Logoutsession from '../../logout/logoutsession.js'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshTokenApi from '../../refreshtoken/refreshtokenapi';

export function manageCandidateStatusAPI(res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECANDIDATESTATUS_URL,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageCandidateSearchAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGECANDIDATESEARCH_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
export function manageCandidateJobAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECANDIDATEJOBLIST_URL + "?jobid=" + data.jobid + "&candid=" + data.candid,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//send resume link 
export function resumeLink(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.RESUMELINK_URL + "?id=" + Data.id + "&dtypeone=" + Data.dtypeone + "&dtypetwo=" + Data.dtypetwo,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    console.log("response.data", response.data)
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// candidate details list page
export function manageCandidateDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECANDIDATEDETAILS_URL + "?candid=" + data.candId,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// view sms
export function manageCandidateViewSMSAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECANDIDATEVIEWSMS_URL + "?candguid=" + data.candguid + '&jobid=' + data.jobid + '&loggedUserPhNo=' + localStorage.getItem("userPhoneNumber"),
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// view email
export function manageCandidateViewEmailAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECANDIDATEVIEWEMAIL_URL + "?candguid=" + data.candguid + '&jobid=' + data.jobid,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// submit table data
export function manageCandidateUPDATEAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGECANDIDATEUPDATE_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//CREATE POSITION HISTORY
export function createPositionHistoryAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.CREATEPOSITIONHISTORY_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//create skillset 
export function createSkillSetAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.CREATESKILLSET_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
/* Added this code for  POW-377. Prasanna. November 28 2023.. Code Start */
//create candidate preferences 
export function createCandidatePrefAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.CREATECANDIDATEPREF_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
/* Prasanna. November 28 2023. Code End */

export function manageCandidateSkillSetAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECANDIDATESKILLSET_URL + "?candid" + data.candid,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}