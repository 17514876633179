// import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import React from 'react';
// import { useDispatch } from 'react-redux';
// import * as quickPanelActions from './store/actions';

function QuickPanelToggleButton(props) {
	// const dispatch = useDispatch();

	return (
		// onClick={ev => dispatch(quickPanelActions.toggleQuickPanel())}
		<IconButton style={{display:"none",background:"transparent"}}>
			{props.children}
		</IconButton>
	);
}

QuickPanelToggleButton.defaultProps = {
	// children: <Icon>format_list_bulleted</Icon>
};

export default QuickPanelToggleButton;
