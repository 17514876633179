import * as manageClientAPI from 'app/services/teamleadAPI/manageclientApi';
//create business unit
export function ClientCreateActions(data,res){
	manageClientAPI.ClientCreate(data,(response)=>{
		if (response.token) {
			manageClientAPI.ClientCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit business unit
export function ClientEditActions(data,res){
	manageClientAPI.ClientEdit(data,(response)=>{
		if (response.token) {
			manageClientAPI.ClientEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
 /* Added this code for  POW-464. Prasanna. February 02 2023.. Code Start */
//delete business unit
export function ClientDeleteActions(data,res){
	manageClientAPI.ClientDelete(data,(response)=>{
		if (response.token) {
			manageClientAPI.ClientDelete(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
/* Prasanna. February 02 2023. Code End */
//search business unit
export function ClientSearchActions(data,res){
	manageClientAPI.ClientSearch(data,(response)=>{
		if (response.token) {
			manageClientAPI.ClientSearch(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
