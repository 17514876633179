import React from 'react';

const CandidateDetailsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/upload/candidatedetails',
			component:React.lazy(() => import('./CandidateDetails')) 
        }
	]
};

export default CandidateDetailsConfig;