// import AppBar from '@material-ui/core/AppBar';
// import { ThemeProvider } from '@material-ui/core/styles';
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useSelector } from 'react-redux';

function FooterLayout3(props) {
	// const footerTheme = useSelector(({ fuse }) => fuse.settings.footerTheme);

	return (
		<Typography style={{ margin: "15px" }}>
			{/* @ Copy   right 2020 Solomons International, LLC */}
		</Typography>
		// <ThemeProvider theme={footerTheme}>
		/* <AppBar id="fuse-footer" className="relative z-10" color="default">
			<Toolbar className="px-16 py-0 flex items-center">
				<Typography>
@ Copyright 2020 Solomons International,LLC
</Typography>
			</Toolbar>
		</AppBar> */
		// </ThemeProvider>
	);
}

export default React.memo(FooterLayout3);
