/* Added this code for  POW-521. Prasanna. August 07 2023.. Code Start */
import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
import * as APP from 'app/constants/AppConstants'
import Logoutsession from '../../logout/logoutsession.js'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshTokenApi from '../../refreshtoken/refreshtokenapi.js';

//create business unit
export function VMSPasswordsCreate(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGEVMSPASSWORDSCREATE_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//edit business unit
export function VMSPasswordsEdit(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGEVMSPASSWORDSEDIT_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                });
    });
}
 /* Added this code for  POW-464. Prasanna. February 02 2023.. Code Start */
//Delete business unit
export function VMSPasswordsDelete(data, res) {
    return new Promise((resolve, reject) => {
        axios.delete(API.MANAGEVMSPASSWORDSDELETE_URL +"?id=" + data.id,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                });
    });
}
/* Prasanna. February 02 2023. Code End */
//search business unit
export function VMSPasswordsSearch(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGEVMSPASSWORDSSEARCH_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                });
    });
}
/* Prasanna. August 07 2023. Code End */