import React from 'react';

const EmploymentTypeConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/organizationalsetup/employmenttype',
			component:React.lazy(() => import('./EmploymentType')) 
        }
	]
};

export default EmploymentTypeConfig;