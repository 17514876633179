import * as manageVendorAPI from 'app/services/teamleadAPI/managevendorApi';
//create business unit
export function ManagevendorCreateActions(data,res){
	manageVendorAPI.ManagevendorCreate(data,(response)=>{
		if (response.token) {
			manageVendorAPI.ManagevendorCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit business unit
export function ManagevendorEditActions(data,res){
	manageVendorAPI.ManagevendorEdit(data,(response)=>{
		if (response.token) {
			manageVendorAPI.ManagevendorEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//search business unit
export function ManagevendorSearchActions(data,res){
	manageVendorAPI.ManagevendorSearch(data,(response)=>{
		if (response.token) {
			manageVendorAPI.ManagevendorSearch(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
