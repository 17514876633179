import * as managejobapi from "app/services/recruiterapi/ManageJobAPI";

export const MAANAGEJOBDETAILS_ERROR = "MAANAGEJOBDETAILS_ERROR";
export const MAANAGEJOBDETAILS_SUCCESS = "MAANAGEJOBDETAILS_SUCCESS";

// Manage job Details
// export function managejobDetailsActions(formData) {
// 	console.log("Action data",formData)
// 	return dispatch =>
// 	managejobapi.managejobDetailsAPI(formData)

// 			.then(response => {
// 				console.log("managereducer", response)

// 				return dispatch({
// 					type: MAANAGEJOBDETAILS_SUCCESS,
// 					payload: response
// 				})

// 			})
// 			.catch(error => {
// 				console.log("manageerrorreducer", error)
// 				return dispatch({
// 					type: MAANAGEJOBDETAILS_ERROR,
// 					payload: error
// 				})

// 			});
// }

//search
export function managejobSearchActions(data, res) {
  managejobapi.managejobSearchAPI(data, (response) => {
    if (response.token) {
      managejobapi.managejobSearchAPI(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}

// Candidate List
export function managejobListActions(data, res) {
  managejobapi.managejoblistAPI(data, (response) => {
    if (response.token) {
      managejobapi.managejoblistAPI(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}

export function managejobDetailsActions(data, res) {
  managejobapi.managejobDetailsAPI(data, (response) => {
    if (response.token) {
      managejobapi.managejobDetailsAPI(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}

// job Status whole list
export function manageJobStatusListActions(data, res) {
  managejobapi.getJobStatusList(data, (response) => {
    if (response.token) {
      managejobapi.getJobStatusList(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}

//post Job on Dice Action Pow-128.October 7 2022 Ayush... Code Starts//
export function postJobOnDiceAction(data, res) {
  managejobapi.postJobOnDice(data, (response) => {
    if (response.token) {
      managejobapi.postJobOnDice(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}

//post Job on Dice Action Pow-128. October Code End//

//get dashboard data pow-99 starts
export function manageDashboardDataAction(data, res) {
  managejobapi.getDashboardData(data, (response) => {
    if (response.token) {
      managejobapi.getDashboardData(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}
//get dashboard data pow-99 ends//

//get all job unattended action pow-99 starts
export function manageJobUnattendedAction(data, res) {
  managejobapi.getAllJobUnattended(data, (response) => {
    if (response.token) {
      managejobapi.getAllJobUnattended(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}
//get all job unattended action pow-99 starts

//get all jobs client totals action POW_99 starts

export function manageAllJobClientsTotalAction(data, res) {
  managejobapi.getAllJobsClientsTotal(data, (response) => {
    if (response.token) {
      managejobapi.getAllJobsClientsTotal(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}

//get all jobs client totals action POW_99 ends

//get Recruiters Submission Action POW-99 starts

export function manageRecruiterSubmissionsAction(data, res) {
  managejobapi.getRecruiterSubmissions(data, (response) => {
    if (response.token) {
      managejobapi.getRecruiterSubmissions(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}

//get Recruiters Submission Action POW-99 ends

//get assigned recruiters Jobs Ation POW-99 starts

export function manageAssignedRecruitersJobsAction(data, res) {
  managejobapi.getAssignedRecruitersJobs(data, (response) => {
    if (response.token) {
      managejobapi.getAssignedRecruitersJobs(data, (response) => {
        return res(response);
      });
    } else {
      return res(response);
    }
  });
}

//get assigned recruiters Jobs Ation POW-99 starts

/* Added this code for  POW-486. Dharmateja. February 24 2023.. Code Start */
export function manageCandidateStatusActions(data, res) {
	managejobapi.manageCandidateStatusAPI(data, (response) => {
		if (response.token) {
			managejobapi.manageCandidateStatusAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
/* Dharmateja. February 24 2023.. Code End */
