import { combineReducers } from 'redux';
import login from './login.reducer';
import register from './register.reducer';
import user from './user.reducer';
import forgot from './forgot.reducer';
import businessunits from './businessunits.reducer';
import jobdetails from './jobdetails.reducer';
import Managereducer from './manage.reducer';
import pageassign from './pageassignreducer';
const authReducers = combineReducers({
	user,
	login,
	register,
	forgot,
	businessunits,
	jobdetails,
	Managereducer,
	pageassign,
});

export default authReducers;
