/* Added this code for  POW-521. Prasanna. August 03 2023.. Code Start */
import React from 'react';

const ManageVMSPasswordsConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/teamlead/manageVMSPasswords',
            component: React.lazy(() => import('./manageVMSPasswords'))
        }
    ]
};

export default ManageVMSPasswordsConfig;
/* Prasanna. August 03 2023. Code End */