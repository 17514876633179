import * as manageRecruitersNetworkAPI from 'app/services/teamleadAPI/manageRecruitersNetworkApi';
//create Recruiters Network
export function RecruitersNetworkCreateActions(data,res){
	manageRecruitersNetworkAPI.RecruitersNetworkCreate(data,(response)=>{
		if (response.token) {
			manageRecruitersNetworkAPI.RecruitersNetworkCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit Recruiters Network
export function RecruitersNetworkEditActions(data,res){
	manageRecruitersNetworkAPI.RecruitersNetworkEdit(data,(response)=>{
		if (response.token) {
			manageRecruitersNetworkAPI.RecruitersNetworkEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
/* Added this code for  POW-472. Dharmateja.  January 31 2023.. Code Start */
export function RecruitersNetworkDeleteActions(data,res){
	manageRecruitersNetworkAPI.RecruitersNetworkDelete(data,(response)=>{
		if (response.token) {
			manageRecruitersNetworkAPI.RecruitersNetworkDelete(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
/* Dharmateja. January 31 2023. Code End */
//search Recruiters Network
export function RecruitersNetworkSearchActions(data,res){
	manageRecruitersNetworkAPI.RecruitersNetworkSearch(data,(response)=>{
		if (response.token) {
			manageRecruitersNetworkAPI.RecruitersNetworkSearch(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
