import React from 'react';

const ManageCandidatesConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/upload/managecandidates',
			component: React.lazy(() => import('./ManageCandidates'))
		}
	]
};

export default ManageCandidatesConfig;