
import * as candidateChatBotAPi from 'app/services/recruiterapi/CandidateChatBotAPi.js';

export const CANDIDATEJOB_ERROR = 'CANDIDATEJOB_ERROR';
export const CANDIDATEJOB_SUCCESS = 'CANDIDATEJOB_SUCCESS';



// Candidate description 
export function CandidateChatDescription(data, res) {
	candidateChatBotAPi.CandidateChatDescriptionAPI(data, (response) => {
        if (response.token) {
			candidateChatBotAPi.CandidateChatDescriptionAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
export function CandidateChatBotSend(data, res) {
	candidateChatBotAPi.CandidateChatBotSendAPI(data, (response) => {
		if (response.token) {
			candidateChatBotAPi.CandidateChatBotSendAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}


// export function CandidateChatBotSend(formData, res) {
// 	return dispatch =>
// 	candidateChatBotAPi.CandidateChatBotSendAPI(formData)
// 			.then(response => {
// 				return dispatch({
// 					type: CANDIDATEJOB_SUCCESS,
// 					payload: response
// 				});
// 			})
// 			.catch(error => {
// 				return dispatch({
// 					type: CANDIDATEJOB_ERROR,
// 					payload: error
// 				});
// 			});
// }

