import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { } from 'react';
import { Link } from 'react-router-dom';
// import Auth0LoginTab from './tabs/Auth0LoginTab';
import FirebaseLoginTab from './tabs/FirebaseLoginTab';
// import JWTLoginTab from './tabs/JWTLoginTab';
import './tabs/login.css'

const useStyles = makeStyles(theme => ({
	root: {
		background: `linear-gradient(to right, #243689 0%, ${darken(
			"#132147",
			0.5
		)} 70%)`,
		color: theme.palette.primary.contrastText
	},
	imagesize: {
		marginBottom: "-25px",
		width: "20rem",
		alignSelf: 'center'
	}
}));

function Login() {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0')}>
			<div className="flex flex-col flex-grow-0 items-center text-white p-16 text-center login-div md:items-start md:flex-shrink-0 md:flex-1 md:text-left">
				<FuseAnimate animation="transition.expandIn">
					<img className="w-128 mb-32" src="assets/images/logos/logo5.png" alt="logo" className={classes.imagesize} />
				</FuseAnimate>
				<FuseAnimate delay={400}>
					<Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16 login-h66" style={{ marginTop: "-20px" , marginLeft: '20%'}}>
						Empowering Recruiters, Enabling Candidates
					</Typography>
				</FuseAnimate>

				<FuseAnimate animation="transition.slideUpIn" delay={300}>
					<Typography variant="h3" color="inherit" className="font-light login-h6">
						Welcome to the PowerRecruit !
					</Typography>
				</FuseAnimate>
			<div className="sub-div">
				<FuseAnimate delay={400}>
					<Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16" style={{ marginTop: "-20px" , marginLeft: '20%'}}>
						Upload Your Job
					</Typography>
				</FuseAnimate>
				<FuseAnimate delay={400}>
					<Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16" style={{ marginTop: "-20px" , marginLeft: '20%'}}>
						Search for Candidates
					</Typography>
				</FuseAnimate>
				<FuseAnimate delay={400}>
					<Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16" style={{ marginTop: "-20px" , marginLeft: '20%'}}>
						Review Best Matching Candidates
					</Typography>
				</FuseAnimate>
				<FuseAnimate delay={400}>
					<Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16" style={{ marginTop: "-20px" , marginLeft: '20%'}}>
						Select and active chatbot
					</Typography>
				</FuseAnimate>
				<FuseAnimate delay={400}>
					<Typography variant="subtitle1" color="inherit" className="max-w-512 mt-16" style={{ marginTop: "-20px" , marginLeft: '20%'}}>
						Call and submit
					</Typography>
				</FuseAnimate>
			</div>

			</div>

			<FuseAnimate animation={{ translateX: [0, '100%'] }}>
				<Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>
					<CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">
						<Typography variant="h6" className="text-center md:w-full mb-48">
							LOGIN TO YOUR ACCOUNT
						</Typography>

						<FirebaseLoginTab />
						<div style={{ marginTop: "6px" }}>
							<Link className="font-medium" to="/forgotpass">
								Forgot password
							</Link>
							<Link className="font-medium" to="/forgotuser" style={{ marginLeft: "70px" }}>
								Forgot username
							</Link>
						</div>

						<div className="flex flex-col items-center justify-center pt-32">
							{/* <span className="font-medium">Don't have an account?</span>


							<Link className="font-medium" to="/register">
								Create an account
							</Link> */}
							{/* <Link className="font-medium mt-8" to="/">
								Back to Dashboard
							</Link> */}
						</div>
					</CardContent>
				</Card>
			</FuseAnimate>
		</div>
	);
}

export default Login;
