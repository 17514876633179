import React from 'react';

const JobDetailsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/upload/jobdetails',
			component:React.lazy(() => import('./JobDetails')) 
        }
	]
};

export default JobDetailsConfig;