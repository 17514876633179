import navigationConfig from 'app/fuse-configs/navigationConfig';
import recruiterConfig from 'app/fuse-configs/recruiterConfig';
import teamleadConfig from 'app/fuse-configs/teamleadConfig';
import * as Actions from '../../actions/fuse';

const getRole = localStorage.getItem("Role")

// const initialState = getRole == 2 ? recruiterConfig : getRole == 4 ? teamleadConfig : navigationConfig;
const initialState =navigationConfig ;

const navigation = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_NAVIGATION: {
			return [...state];
		}
		case Actions.SET_NAVIGATION: {
			return [...action.navigation];
		}
		case Actions.RESET_NAVIGATION: {
			return [...initialState];
		}
		default: {
			return state;
		}
	}
};

export default navigation;
