
import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';


i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

console.log("getrole1231", localStorage.getItem("Role"))
const recruiterConfig = [
	localStorage.getItem('Page') ? {
		id: 'dashboard',
		title: 'Home',
		translate: 'Home',
		type: 'collapse',
		icon: 'dashboard',
		children: [
			localStorage.getItem('Role') == 2 ? {
				id: "analytics",
				title: "About",
				type: "item",
				url: "/dashboard/analytics",
				// role: getAccess('dashboard'),
			} : {},
		]
	} : {},
	localStorage.getItem('Page') && (localStorage.getItem('Page').split(",").includes(1) || localStorage.getItem('Page').split(",").includes(2)) ? {
		id: 'Jobs',
		title: 'Jobs',
		translate: 'Jobs',
		type: 'collapse',
		icon: 'cloud_upload',
		children: [
			localStorage.getItem('Page').split(",").includes(1) ? {
				id: "Upload job",
				title: "Upload job",
				type: "item",
				url: "/upload/uploadjobs"
			} : {},
			localStorage.getItem('Page').split(",").includes(2) ? {
				id: "Manage Jobs",
				title: "Manage Jobs",
				type: "item",
				url: "/upload/managejob"
			} : {},
		]
	} : {},

	localStorage.getItem('Page') && (localStorage.getItem('Page').split(",").includes(3) || localStorage.getItem('Page').split(",").includes(15)) ? {
		id: 'Candidates',
		title: `Manage Candidate / Consultant`,
		translate: `Manage Candidate / Consultant`,
		type: 'collapse',
		icon: 'supervisor_account',
		children: [
			localStorage.getItem('Page').split(",").includes(3) ? {
				id: "Manage Candidates",
				title: "Manage Candidates",
				type: "item",
				url: "/upload/managecandidates"
			} : {},
			localStorage.getItem('Page').split(",").includes(15) ? {
				id: "Manage Consultant",
				title: "Manage Consultant",
				type: "item",
				url: "/upload/manageconsultant"
			} : {},
		]
	} : {},

	localStorage.getItem('Page') && (localStorage.getItem('Page').split(",").includes(16) || localStorage.getItem('Page').split(",").includes(17)) ? {
		id: 'History',
		title: 'History',
		translate: 'History',
		type: 'collapse',
		icon: 'history',
		children: [
			localStorage.getItem('Page').split(",").includes(16) ? {
				id: "Match history",
				title: "Match history",
				type: "item",
				url: "/upload/matchhistory"
			} : {},
			localStorage.getItem('Page').split(",").includes(17) ? {
				id: "ChatBot history",
				title: "ChatBot history",
				type: "item",
				url: "/upload/chatbothistory"
			} : {},

		]
	} : {},
];




export default recruiterConfig;
