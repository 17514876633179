
import * as manageConsultant from 'app/services/recruiterapi/ManageConsultant';

export function manageConsultantSearchActions(data, res) {
	manageConsultant.manageConsultantSearchAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantSearchAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantStatusAction(data, res) {
	manageConsultant.manageConsultantStatusAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantSearchAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantUPDATEActions(data, res) {
	manageConsultant.manageConsultantUPDATEAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantSearchAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
export function manageConsultantADDActions(data, res) {
	manageConsultant.manageConsultantADDAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantADDAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}  

export function manageConsultantDetailsActions(data, res) {
	manageConsultant.manageConsultantDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantPersonalDetailsActions(data, res) {
	manageConsultant.manageConsultantPersonalDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantPersonalDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantEmploymentDetailsActions(data, res) {
	manageConsultant.manageConsultantEmploymentDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantEmploymentDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantSkillsDetailsActions(data, res) {
	manageConsultant.manageConsultantSkillsDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantSkillsDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantProjectDetailsActions(data, res) {
	manageConsultant.manageConsultantProjectDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantProjectDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantProjectTimelineDetailsActions(data, res) {
	manageConsultant.manageConsultantProjectTimelineDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantProjectTimelineDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantHourlyRatesAndFeesDetailsActions(data, res) {
	manageConsultant.manageConsultantHourlyRatesAndFeesDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantHourlyRatesAndFeesDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantPriorProjectsDetailsActions(data, res) {
	manageConsultant.manageConsultantPriorProjectsDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantPriorProjectsDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
export function manageConsultantWeeklyOccurringNumbersActions(data, res) {
	manageConsultant.manageConsultantWeeklyOccurringNumbersAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantWeeklyOccurringNumbersAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantPriorProjectsDetailsAddActions(data, res) {
	manageConsultant.manageConsultantPriorProjectsDetailsAddAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantPriorProjectsDetailsAddAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantGetWeeklyOccurringNumbersDetailsAction(data, res) {
	manageConsultant.manageConsultantGetWeeklyOccurringNumbersDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantGetWeeklyOccurringNumbersDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantAddWeeklyOccurringNumbersDetailsAction(data, res) {
	manageConsultant.manageConsultantGetWeeklyOccurringNumbersAddDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantGetWeeklyOccurringNumbersAddDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
export function manageConsultantManagerAddDetailsAction(data, res) {
	manageConsultant.manageConsultantManagerAddDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantManagerAddDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantManagerUpdateDetailsAction(data, res) {
	manageConsultant.manageConsultantManagerUpdateDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantManagerUpdateDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageConsultantManagerDeleteDetailsAction(data, res) {
	manageConsultant.manageConsultantManagerDeleteDetailsAPI(data, (response) => {
		if (response.token) {
			manageConsultant.manageConsultantManagerDeleteDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}