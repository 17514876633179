import * as candidatesjobapi from 'app/services/dashboard/candidatejobstatus';

export function candidatejoblistActions(data,res) {
    candidatesjobapi.candidateJobList(data,(response)=>{
		if (response.token) {
			candidatesjobapi.candidateJobList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

//create 
export function candidatejobCreateActions(data,res){
	candidatesjobapi.candidatejobCreate(data,(response)=>{
		if (response.token) {
			candidatesjobapi.candidatejobCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit 
export function candidatejobEditActions(data,res){
	candidatesjobapi.candidatejobEdit(data,(response)=>{
		if (response.token) {
			candidatesjobapi.candidatejobEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//change status
export function candidatejobstatusActions(data,res) {
	candidatesjobapi.candidatejobStatusUpdate(data,(response)=>{
		if (response.token) {
			candidatesjobapi.candidatejobStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})			
}