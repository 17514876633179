import Avatar from '@material-ui/core/Avatar';
import axios from 'axios'
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import * as API from 'app/constants/ApiConstants'
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
// import * as authActions from 'app/auth/store/actions';
import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Modal from './profileModal.js'
import * as authActionsBusiness from 'app/auth/store/actions/dashboardactions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import 'src/app/main/Toast.css';
import '../../../../src/app/main/Toast.css';
// import logo from '../../../Images/solomonLogo.PNG';

function UserMenu(props) {
	// const dispatch = useDispatch();
	// const user = useSelector(({ auth }) => auth.user);
	const history = useHistory()

	useEffect(() => {
		if (!window.location.href.includes("?sessionId=")) {
			businessList()
			const setVendors = () => {
				var activatedBUs = localStorage.getItem("activatedBUs").split(",");
				var businessList = JSON.parse(localStorage.getItem("businessSearchContent"));
				if (activatedBUs, businessList) {
					getVendorsforActivatedBUs()
					clearInterval(intervalId);
				}
			}
			const intervalId = setInterval(setVendors, 1000);
    		return () => clearInterval(intervalId);
		}
	}, [])


	function getVendorsforActivatedBUs() {
		var vendors = [];
		var activatedBUs = localStorage.getItem("activatedBUs").split(",");
		var businessList = JSON.parse(localStorage.getItem("businessSearchContent"));
		var codes = [];
		for (var i = 0; i <= activatedBUs.length; i++) {
			businessList.forEach(element1 => {
				if(activatedBUs[i] === element1.bid) {
					codes.push(element1.code);
				}
			})
		};
		codes.forEach(element => {
			if(element === 'USA') {
				vendors = vendors.concat(API.USA_VENDORS);
			}
			else if(element === 'CAN' || element === 'CA') {
				vendors = vendors.concat(API.CAN_VENDORS)
			}
		});
		localStorage.setItem("vendors", vendors);
		console.log(vendors);
		return vendors;
	}

	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
		businessList()
	};

	const userMenuClose = () => {
		// history.push("/")
		setUserMenu(null);
	};
	const logOut = () => {
		history.push("/")
		window.location.reload()
		localStorage.clear();
	};
	const [open, setOpen] = React.useState(false);
	const handleClose = () => {
		setOpen(false);
	};
	const SearchModalOpen = () => {
		setOpen(true)
	}
	const [getBusinessListData, setBusinessListData] = useState([])
	const businessList = () => {
		let businesslist =
		{
			"page_no": 0,
			"limit": 1000,
			"sortby": "DESC",
			"orderby": ""
		}
		authActionsBusiness.businessunitlistActions(businesslist, (res) => {
			if (res.businessSearch) {
				//initializing the list in state which comes from api
				// setBusinessListData(res.businessSearch.content)
				// console.log('dta',res.businessSearch.content)
				let arr = []
				res.businessSearch.content.map((label_res) => {
					arr.push({
						"status": label_res.status,
						"code": label_res.code,
						'bid': label_res.bid,

					})
				})
				setBusinessListData(arr);
				localStorage.setItem("businessSearchContent", JSON.stringify(res.businessSearch.content));
			}

			else {
				toast(res.message ? res.message : res.erroMessage, {
					position: toast.POSITION.TOP_CENTER,
					className: 'foo-bar'
				});
			}
		})

	}

// 	const businessList = () => {
// 		axios.get(API.BUSINESSUNITDASHBOARD_URL, {
// 			headers: {
// 				'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
// 			}
// 		}).then((response) => {
// 			if(response.data !== 'Failed'){
// 			let arr = []
// 			response.data.map((data) => {
// 				arr.push({
// 					"status": data.status,
// 					"code": data.code,
// 					"bid": data.bid
// 				})
// 			})
// 			setBusinessListData(arr);
// 			localStorage.setItem("Data", JSON.stringify(response.data));
// 		} else {
// 			toast("Failed To Load Business Units", {
// 				position: toast.POSITION.TOP_CENTER,
// 				className: 'foo-bar'
// 			});
// 		}
// 		})
// 		.catch ((error) => {
// 			console.log("ERROR")
// 		}) 
// }


	const sumbitbusiness = (id) => {
		console.log("id", id)
		let data =
		{
			"businessUnitId": id,
		}
		authActionsBusiness.profilebusinessActions(data, (res) => {
			if (res.erroMessage) {
				handleClose()
				toast(res.erroMessage, {
					position: toast.POSITION.TOP_CENTER,
					className: 'foo-bar',
				});
			}
			else if (res.message) {
				handleClose()
				window.location.reload()
				toast("Business Unit Applied", {
					position: toast.POSITION.TOP_CENTER,
					className: 'foo-bar',
				});
			}
		})
	}
	return (
		<>

			<div className=" flex headerList">
				<Modal
					open={open}
					handleClose={handleClose}
					getBusinessListData={getBusinessListData}
					sumbitbusiness={sumbitbusiness}

				/>
				{/* {window.location.href.includes("/bot?") ?
					<img src={logo} className="logoforchatbotinheader" />
					: ""
				} */}
				{window.location.href.includes("/bot?sessionId") ?
					<div className="Header-User"><span style={{ color: '#384AAC', fontSize: "13px", fontWeight: "bold" }}>Solomons International</span></div>
					:
					<div className="Header-User">Welcome Back <span style={{ color: '#384AAC ' }}>{localStorage.getItem('Name')}</span></div>
				}
				<ToastContainer />

				<div className="headerChat">
					{/* <Avatar className="user-logo-photo"  style={{ marginRight: "-10px" }} src="assets/images/logos/Vector.png" width="10" height="10" />	 */}
				</div>
			</div>
			<div className="imgCheck headerList1 flex " onClick={userMenuClick} >
				<Avatar className="user-photo" src="assets/images/logos/settingsIcon.png" width="10" height="10" />
			</div>
			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{/* {!user.role || user.role.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
						<MenuItem component={Link} to="/register" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Register" />
						</MenuItem>
					</>
				) : (
						<> */}
				{/* <MenuItem component={Link} to="/pages/profile" onClick={userMenuClose} role="button">
								<ListItemIcon className="min-w-40">
									<Icon>account_circle</Icon>
								</ListItemIcon>
								<ListItemText primary="My Profile" />
							</MenuItem> */}
				{/* <MenuItem component={Link} to="/apps/mail" onClick={userMenuClose} role="button">
								<ListItemIcon className="min-w-40">
									<Icon>mail</Icon>
								</ListItemIcon>
								<ListItemText primary="Inbox" />
							</MenuItem> */}


				<MenuItem
					onClick={SearchModalOpen}
				// onClick={logOut}
				>
					<ListItemIcon className="min-w-40">
						<Icon>person</Icon>
					</ListItemIcon>
					<ListItemText primary="Business Unit" />
				</MenuItem>



				<MenuItem
					onClick={logOut}
				// onClick={logOut}
				>
					<ListItemIcon className="min-w-40">
						<Icon>exit_to_app</Icon>
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</MenuItem>

				{/* </>
					)} */}
			</Popover>

		</>
	);
}

export default UserMenu;
