import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
import * as APP from 'app/constants/AppConstants'
import Logoutsession from '../../logout/logoutsession.js'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshTokenApi from '../../refreshtoken/refreshtokenapi';

export function manageConsultantSearchAPI(data, res) {
    console.log('Consultant Data -> ', data)
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGECONSULTANTSEARCH_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
export function manageConsultantStatusAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECONSULTANTSTATUS_URL + "?consultantId=" + data.consultantId + "&action=" + data.action, {
            headers: {
                'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
            }
        }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data);
                    // resolve(response.data.businessSearch);
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}

export function manageConsultantUPDATEAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGECONSULTANTUPDATE_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}



export function manageConsultantADDAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGECONSULTANTADD_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}


export function manageConsultantDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECONSULTANTDetails_URL +"?id="+data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantPersonalDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.put(API.MANAGECONSULTANTPERSONALDERAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantSkillsDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.put(API.MANAGECONSULTANTSkillsDERAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantEmploymentDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.put(API.MANAGECONSULTANTEMPLOYMENTDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}


export function manageConsultantProjectDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.put(API.MANAGECONSULTANTPROJECTDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}


export function manageConsultantProjectTimelineDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.put(API.MANAGECONSULTANTPROJECTTIMELINEDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}


export function manageConsultantHourlyRatesAndFeesDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.put(API.MANAGECONSULTANTHOURLYRATEANDFEESDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantPriorProjectsDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.put(API.MANAGECONSULTANTPRIORPROJECTSDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantWeeklyOccurringNumbersAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.put(API.MANAGECONSULTANTWEEKLOCCURRINGNUMBERDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantPriorProjectsDetailsAddAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGECONSULTANTPRIORPROJECTSADDDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantGetWeeklyOccurringNumbersDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECONSULTANTWEEKLOCCURRINGNUMBERGETDETAILS_URL +"?id=" + data.id + "&year="+ data.year + "&month=" + data.month,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantManagerAddDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGECONSULTANTMANAGERDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}


export function manageConsultantManagerUpdateDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.put(API.MANAGECONSULTANTMANAGERUPDATEDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantGetWeeklyOccurringNumbersAddDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MANAGECONSULTANTWEEKLOCCURRINGNUMBERADDDETAILS_URL ,data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageConsultantManagerDeleteDetailsAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.delete(API.MANAGECONSULTANTMANAGERDELETEDETAILS_URL +"?id=" + data.id,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}