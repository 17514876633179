import FuseAnimate from '@fuse/core/FuseAnimate';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as authActions from 'app/auth/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
	root: {
		background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${theme.palette.primary.dark} 80%)`,
		color: theme.palette.primary.contrastText
	}
}));

function ForgotPasswordPage() {
	const dispatch = useDispatch();
	const forgotpasserror = useSelector(({ auth }) => auth.forgot.forgotpasserror);
	const forgotpasssuccess = useSelector(({ auth }) => auth.forgot.forgotpasssuccess);
	const classes = useStyles();
	const { form, handleChange, resetForm } = useForm({
		username: ''
	});

	const [message, errorFunction] = useState(false)
	const [successmessage, successFunction] = useState(false)
	// function
	function isFormValid() {
		return form.username.length > 0;
	}
	useEffect(() => {
		console.log('loginsuccessful');
		if (forgotpasserror && (forgotpasserror.erroMessage)) {
			errorFunction(true)
			successFunction(false)
		}
		else if (forgotpasssuccess && (forgotpasssuccess.message)) {
			successFunction(true)
			errorFunction(false)
			resetForm();
		}
	}, [forgotpasserror, forgotpasssuccess]);

	function handleSubmit(ev) {
		ev.preventDefault();
		dispatch(authActions.forgotpassActions(form.username))
		// resetForm();
	}

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimate animation="transition.expandIn">
					<Card className="w-full max-w-384">
						<CardContent className="flex flex-col items-center justify-center p-32">
							<div className="w-128 m-32">
								<img src="assets/images/logos/fuse1.png" alt="logo" />
							</div>
							<h2>Forgot Password</h2>
							<Typography variant="p" className="mt-16 mb-32">
								Enter your username and your password will be reset and emailed to you
							</Typography>

							<form
								name="recoverForm"
								noValidate
								className="flex flex-col justify-center w-full"
								onSubmit={handleSubmit}
							>
								<TextField
									className="mb-16"
									label="Username"
									autoFocus
									type="text"
									name="username"
									value={form.username}
									onChange={handleChange}
									variant="outlined"
									// required={true}
									fullWidth
								/>
								{message == true ?
									<Alert severity="error">{forgotpasserror ? forgotpasserror.erroMessage : ''}</Alert>
									:
									successmessage == true ?
										<Alert severity="success">{forgotpasssuccess ? forgotpasssuccess.message : ''}</Alert>
										:
										''
								}
								<Button
									variant="contained"
									color="primary"
									className="w-229 mx-auto mt-16"
									aria-label="Reset"
									disabled={!isFormValid()}
									type="submit"
								>
									Request Password Change
								</Button>
							</form>

							<div className="flex flex-col items-center justify-center pt-32 pb-24">
								<Link className="font-medium" to="/">
									Go back to login
								</Link>

							</div>
							<div>
								@ Copyright 2020 Solomons International, LLC
								</div>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
}

export default ForgotPasswordPage;
