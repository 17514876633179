import * as clientjobapi from 'app/services/dashboard/clientjobstatus';

export function clientjoblistActions(data,res) {
	// return dispatch =>
    clientjobapi.clientJobList(data,(response)=>{
		if (response.token) {
			clientjobapi.clientJobList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
			// .then(user => {
            //     console.log("businessunitlistuser", user)
			// 	// dispatch(UserActions.businessUnitListData(user));
			// 	return dispatch({
			// 		type: BUSINESSUNIT_LIST,
			// 		payload:user
			// 	});
			// })
			// .catch(error => {
			// 	console.log("error", error)
			// 	return dispatch({
			// 		type: BUSINESSUNITLIST_ERROR,
			// 		payload: error
			// 	});
			// });
}

//create 
export function clientjobCreateActions(data,res){
	clientjobapi.clientjobCreate(data,(response)=>{
		if (response.token) {
			clientjobapi.clientjobCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit 
export function clientjobEditActions(data,res){
	clientjobapi.clientjobEdit(data,(response)=>{
		if (response.token) {
			clientjobapi.clientjobEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// search
export function clientjobSearchActions(data,res){
	clientjobapi.clientjob(data,(response)=>{
		if (response.token) {
			clientjobapi.clientjob(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//change status
export function clientjobstatusActions(data,res) {
	clientjobapi.clientjobStatusUpdate(data,(response)=>{
		if (response.token) {
			clientjobapi.clientjobStatusUpdate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})			
}