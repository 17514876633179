    /*Added this code. Anand_POW-492. June 28 2023.. Code Start */

import routes from 'app/store/reducers/fuse/routes.reducer';
import React from 'react';
import ManageSecurityAddRoleConfig from './ManagerSecurityAddRoleConfig';

const ManageSecurityRoleConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/securitysetup/managesecurityrole',
			component:React.lazy(() => import('./ManageSecurityRole')) ,
			
        },
		{
			path: '/securitysetup/managesecurityrole/addrole',
			component:React.lazy(() => import('./tabs/NewModal')) ,
		}
	]
};

export default ManageSecurityRoleConfig;

    /*Added this code. Anand_POW-492. June 28 2023.. Code End */
