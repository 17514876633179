import React from 'react';

const ManageJobConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/upload/managejob',
			component:React.lazy(() => import('./ManageJob')) 
        }
	]
};

export default ManageJobConfig;