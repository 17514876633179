import DashboardConfig from './dashboard/DashboardConfig.js';
import UploadJob from './upload/uploadjob/UploadJobConfig.js';
import UploadJobDetails from './upload/uploadjobdetails/UploadJobDetailsConfig.js';
import CandidateSearch from './upload/candidatesearch/CandidateSearchConfig.js';
import MatchingResults from './upload/matchingdetails/MatchingDetailsConfig.js';
import CandidateChatbotConfig from './upload/Chatbot/CandidateChatbotConfig.js';
import MatchHistoryConfig from './upload/matchhistory/MatchHistoryConfig.js';
import ChatBotHistoryConfig from './upload/chatbothistory/ChatBotHistoryConfig.js';
import ManageJobConfig from './upload/managejobs/ManageJobConfig.js';
import ManageCandidatesConfig from './upload/managecandidates/ManageCandidatesConfig.js';
import ManageConsultantConfig from './upload/manageConsultant/ManageconsultantConfig.js';
import CandidateDetailsConfig from './upload/candidatedetails/CandidateDetailsConfig.js';
import JobDetailsConfig from './upload/Jobdetails/JobDetailsConfig.js'
import ConsultantProjectConfig from './upload/consultantProject/consultantProjectConfig.js'
const recruiterConfigs = [
    DashboardConfig,
    UploadJob,
    UploadJobDetails,
    CandidateSearch,
    MatchingResults,
    CandidateChatbotConfig,
    MatchHistoryConfig,
    ChatBotHistoryConfig,
    ManageJobConfig,
    ManageCandidatesConfig,
    ManageConsultantConfig,
    CandidateDetailsConfig,
    JobDetailsConfig,
    ConsultantProjectConfig
]
export default recruiterConfigs;

