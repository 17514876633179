import * as Actions from '../actions/recruiteractions';
const initialState = {
    data: [],
    success: false,
    candidatesuccess: false,
    runmatchsuccess: false,
};
const jobdetails = (state = initialState, action) => {
    switch (action.type) {
        case Actions.JOBDETAILS_SUCCESS:
            return {
                ...state, ...{ jobdetailssuccess: action.payload, success: true }
            }

        case Actions.JOBDETAILS_ERROR: {
            return { ...state, ...{ jobdetailserror: action.payload, success: false } }
        }
        case Actions.CANDIDATESEARCHLIST_SUCCESS:
            return {
                ...state, ...{ candidatelistsuccess: action.payload, candidatesuccess: true }
            }
        case Actions.CANDIDATESEARCHLIST_ERROR: {
            return { ...state, ...{ candidatelisterror: action.payload, candidatesuccess: false } }
        }
        case Actions.RUNMATCH_SUCCESS:
            return {
                ...state, ...{ runmatchlistsuccess: action.payload, runmatchsuccess: true }
            }
        case Actions.RUNMATCH_ERROR:
            return {
                ...state, ...{ runmatchlisterror: action.payload, runmatchsuccess: false }
            }
        default: {
            return state;
        }
    }
}
export default jobdetails;