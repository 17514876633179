import * as functionsapi from 'app/services/dashboard/functionservices/functionsapi';



export function functionListActions(data, res) {
    functionsapi.functionsList(data, (response) => {
        if(response.token) {
            functionsapi.functionsList(data, (response) => {
                return res(response)
            })
        } else {
            return res(response)
        }
    })
}