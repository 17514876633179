import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
import * as APP from 'app/constants/AppConstants'
import Logoutsession from '../../logout/logoutsession.js'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshTokenApi from '../../refreshtoken/refreshtokenapi';

export function getClientList(data, res) {
    return new Promise((resolve, reject) => {
        // GET_CLIENT_LIST_UR
        axios.get(API.GET_CLIENT_LIST_URL,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                console.log(response.data)
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}
//create client list data
export function addClientList(data, res) {
    return new Promise((resolve, reject) => {
        // ADD_CLIENT_LIST_URL
        axios.post(API.ADD_CLIENT_LIST_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//get client Table data
export function getClientListData(data, res) {
    return new Promise((resolve, reject) => {
        // GET_CLIENT_LIST_DATA_URL
        axios.post(API.GET_CLIENT_LIST_DATA_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//update status
export function updateClientListData(data, res) {
    return new Promise((resolve, reject) => {
      axios.post(API.UPDATE_CLIENT_LIST_DATA_URL , "clientId=" +data.clientid + "&fee=" +data.fee,
            {
            headers: {
                'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
            }
        }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data);
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}

//add Insurances data
export function addInsurancesData(data, res) {
    return new Promise((resolve, reject) => {
      axios.post(API.ADD_INSURANCES_DATA_URL , "insurancesPremiumMonth=" + data.insurancesPremiumMonth + "&insurancesPremiumHr=" + data.insurancesPremiumHr +"&insurancesCost=" +data.insurancesCost +"&factoringInterest=" + data.factoringInterest + "&insurancesCovered=" + data.insurancesCovered + "&factoringDetails=" + data.factoringDetails,{
            headers: {
                "Accept":"application/json",
                'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
            }
        }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data);
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}

//get Insurances data
export function getInsurancesData(data, res) {
    return new Promise((resolve, reject) => {
      axios.get(API.GET_INSURANCES_DATA_URL ,{
            headers: {
                'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
            }
        }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data);
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}

//update Insurances data
export function updateInsurancesData(data, res) {
    return new Promise((resolve, reject) => {
      axios.put(API.UPDATE_INSURANCES_DATA_URL , "id=" + data.id + "&insurancesPremiumMonth=" + data.insurancesPremiumMonth + "&insurancesPremiumHr=" + data.insurancesPremiumHr +"&insurancesCost=" +data.insurancesCost +"&factoringInterest=" + data.factoringInterest + "&insurancesCovered=" + data.insurancesCovered + "&factoringDetails=" + data.factoringDetails,{
            headers: {
                'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
            }
        }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data);
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                       Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}

//get VMS Client data
export function getVMSClientListData(data, res) {
    return new Promise((resolve, reject) => {
      axios.get(API. GET_VMS_CLIENT_DATA_URL ,{
            headers: {
                "Accept":"application/json",
                'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
            }
        }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data);
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}

//get VMS data
export function getVMSListData(data, res) {
    return new Promise((resolve, reject) => {
      axios.get(API.GET_VMS_DATA_URL ,{
            headers: {
                "Accept":"application/json",
                'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
            }
        }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data);
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });

}

//get VMS data
export function getVMSTableData(data, res) {
    return new Promise((resolve, reject) => {
        // GET_CLIENT_LIST_DATA_URL
        axios.post(API.GET_VMS_Table_DATA_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//add VMS data
export function addVMSTableData(data, res) {
    return new Promise((resolve, reject) => {
        // GET_CLIENT_LIST_DATA_URL
        axios.post(API.ADD_VMS_Table_DATA_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// //update VMS data
export function updateVMSTableData(data, res) {
    return new Promise((resolve, reject) => {
        // GET_CLIENT_LIST_DATA_URL
        axios.post(API.UPDATE_Table_DATA_URL, "clientId=" + data.clientId + "&fee=" +data.fee,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}


//add VMS data
export function addJobBoardData(data, res) {
    return new Promise((resolve, reject) => {
        // GET_CLIENT_LIST_DATA_URL
        axios.post(API.ADD_JOB_BOARD_DATA_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//delete Job Board data
export function deleteJobBoardData(data, res) {
    return new Promise((resolve, reject) => {
        // GET_CLIENT_LIST_DATA_URL
        axios.delete(API.DELETE_JOB_BOARD_DATA_URL +"?id=" + data.id,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//update Job board data
export function updateJobBoardData(data, res) {
    return new Promise((resolve, reject) => {
        // GET_CLIENT_LIST_DATA_URL
        axios.post(API.UPDATE_JOB_BOARD_DATA_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//get job Board data
export function getJobBoardData(data, res) {
    return new Promise((resolve, reject) => {
        // GET_CLIENT_LIST_DATA_URL
        axios.get(API.GET_JOB_BOARD_DATA_URL,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

