import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import React from 'react';
import navigationConfig from 'app/fuse-configs/navigationConfig'
import { useSelector } from 'react-redux';

function Navigation(props) {
	const navigation = useSelector(({ fuse }) => fuse.navigation);
	const getAccess = (key) => {

	}
// Added this code for  POW-492. Anand. July 10 2023.. Code Start /
	let navigationCheck =  localStorage.getItem('Page') ? [
		// localStorage.getItem('Page') && localStorage.getItem('Page') ? {
	
		localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("23") || localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("24") || localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("25") || localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("22") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /
			id: 'dashboard',
			title: 'Home',
			translate: 'Home',
			type: 'collapse',
			icon: 'dashboard',
			children: [
				// Added this code for  POW-492. Anand. July 10 2023.. Code Start /			
				localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("23") ? {
					// Added this code for  POW-492. Anand. July 10 2023.. Code End /			
					id: "analytics",
					// title: "About",
					title: "Dashboard",
					type: "item",
					// url: "/dashboard",
					url: "/analyticsdashboard",
				} : {},
				// Added this code for  POW-492. Anand. July 10 2023.. Code Start /			
				localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("24") ? {
				// Added this code for  POW-492. Anand. July 10 2023.. Code End /			
					id: "analytics",
					title: "Dashboard",
					type: "item",
					url: "/recruiter/dashboard",
				} : {},
				// Added this code for  POW-492. Anand. July 10 2023.. Code Start /			
				localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("25") ? {
				// Added this code for  POW-492. Anand. July 10 2023.. Code End /			
					id: "analyticss",
					title: "Dashboard",
					type: "item",
					url: "/teamlead/dashboard"
				} : {},
				// Added this code for  POW-492. Anand. July 06 2023.. Code Start /
				localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("22") ? {
					id: "analyticss",
					title: "Automation Dashboard",
					type: "item",
					url: "/recruiter/automationdashboard"
				} : {},
				// Anand. July 06 2023. Code End /
			]
		} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /			
		localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("4")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("5")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("8")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("9")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("10")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("11")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("12")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("19")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("20")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("21")  ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /			
 
			{
				id: 'organizationalsetup',
				title: 'Organizational Setup',
				translate: 'Organizational Setup',
				type: 'collapse',
				icon: 'business',
				children: [
				// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("4") ? {
				// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						id: "businessunits",
						title: "Business Units",
						type: "item",
						url: "/organizationalsetup/businessunit",
						role: getAccess('businessunits'),
					} : {},
				// Added this code for  POW-492. Anand. July 10 2023.. Code Start /			
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("5") ?
				// Added this code for  POW-492. Anand. July 10 2023.. Code End /	
						{
							id: "clientjobstatus",
							title: "Client Job Status",
							type: "item",
							url: "/organizationalsetup/clientjobstatus",
							role: getAccess('clientjobstatus'),
						} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
						localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("8") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /	
						{
							id: "jobstatus",
							title: "Job Status",
							type: "item",
							url: "/organizationalsetup/jobstatus",
							role: getAccess('jobstatus'),
						} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /	
						localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("20") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /	
						{
							id: "managerdetails",
							title: "Manager Details",
							type: "item",
							url: "/organizationalsetup/managerdetails",
							role: getAccess('jobstatus'),
						} : {},
						// Prasanna. August 25 2022. Code End
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /			
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("9") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /			
						{
							id: "candidatejobstatus",
							title: "Candidate Status",
							type: "item",
							url: "/organizationalsetup/candidatestatus",
							role: getAccess('candidatejobstatus'),
						} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /			
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("10") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /			
						{
							id: "workauthorization",
							title: "Work Authorization",
							type: "item",
							url: "/organizationalsetup/workauthorization",
							role: getAccess('workauthorization'),
						} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /			
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("11") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /			
						{
							id: "sourcenames",
							title: "Source Types",
							type: "item",
							url: "/organizationalsetup/sourcenames",
							role: getAccess('sourcenames'),
						} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("12") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						{
							id: "chatbottypes",
							title: "Chatbot Types",
							type: "item",
							url: "/organizationalsetup/chatbottype",
							role: getAccess('chatbottypes'),
						} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /	
						localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("21") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /	
						{
							id: "SetupExsetupexpensespenses",
							title: "Setup Expenses",
							type: "item",
							url: "/organizationalsetup/setupexpenses",
							role: getAccess('setupexpenses'),
						} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /	
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("19") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /	
                        {
							id: "employmenttypes",
							title: "Employment Types",
							type: "item",
							url: "/organizationalsetup/employmenttype",
							role: getAccess('employmenttypes'),
						} : {},
				]
				// url: '/example'
			} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
		localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("13")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("14") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
				id: 'securitysetup',
				title: 'Security setup',
				translate: 'Security setup',
				type: 'collapse',
				icon: 'lock',
				children: [
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("13") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						{
							id: "managesecurityuser",
							title: "Manage Security User",
							type: "item",
							url: "/securitysetup/managesecurityuser",
							role: getAccess('managesecurityuser'),
						} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("14") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						{
							id: "managesecurityrole",
							title: "Manage Security Role",
							type: "item",
							url: "/securitysetup/managesecurityrole",
							role: getAccess('managesecurityrole'),
						} : {},
				]
			} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
		localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("1")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("2") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
				id: 'Jobs',
				title: 'Jobs',
				translate: 'Jobs',
				type: 'collapse',
				icon: 'cloud_upload',
				children: [
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("1") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						{
							/* Commenting the following code for POW-500. Prasanna. March 30 2023.. Code Start */
							//id: "Upload job",
							//title: "Upload job",
							/* Commenting end. Prasanna. March 30 2023.. March 30 2023.. */
							/* Added this code for  POW-500. Prasanna. March 30 2023.. Code Start */
							id: "Upload Job",
							title: "Upload Job",
							/* Prasanna. March 30 2023. Code End */
							type: "item",
							url: "/upload/uploadjobs"
						} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("2") ?
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						{
							id: "Manage Jobs",
							title: "Manage Jobs",
							type: "item",
							url: "/upload/managejob"
						} : {},
				]
			} : {},
    // Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
		localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("3")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("15") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /					
				id: 'Candidates',
				title: `Manage Candidates / Consultants`,
				translate: `Manage Candidates / Consultants`,
				type: 'collapse',
				icon: 'supervisor_account',
				children: [
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /						
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("3") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /							
						id: "Manage Candidates",
						title: "Manage Candidates",
						type: "item",
						url: "/upload/managecandidates"
					} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("15") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						id: "Manage Consultants",
						title: "Manage Consultants",
						type: "item",
						url: "/upload/manageconsultant"
					} : {},
				]
			} : {},
    // Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
		localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("16")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("17") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
				id: 'History',
				title: 'History',
				translate: 'History',
				type: 'collapse',
				icon: 'history',
				children: [
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("16") ? {
    // Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						id: "Match history",
						title: "Match history",
						type: "item",
						url: "/upload/matchhistory"
					} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("17") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						id: "ChatBot history",
						title: "ChatBot history",
						type: "item",
						url: "/upload/chatbothistory"
					} : {},
				]
			} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /				
		localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("6")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("7")
			|| localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("18") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
				id: 'manage',
				title: `Manage Client's / Vendor's / Network`,
				translate: `Manage Client's / Vendor's / Network`,
				type: 'collapse',
				icon: 'cloud_upload',
				children: [
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("6") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						id: "Manageclient",
						title: "Manage Client",
						type: "item",
						url: "/teamlead/manageclient"
					} : {},
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("7") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /		
						id: "Managevendor",
						title: "Manage Vendor",
						type: "item",
						url: "/teamlead/managevendor"
					} : {},
					// Added this code for  POW-521. Prasanna. August 01 2023.. Code Start /		
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("27") ? {	
											id: "ManageVMSPasswords",
											title: "Manage VMS Passwords",
											type: "item",
											url: "/teamlead/manageVMSPasswords"
										} : {},
										/* Prasanna. August 01 2023. Code End */
	// Added this code for  POW-492. Anand. July 10 2023.. Code Start /					
					localStorage.getItem('Page') && localStorage.getItem('Page').split(",").includes("18") ? {
	// Added this code for  POW-492. Anand. July 10 2023.. Code End /	
						id: "Managerecruitersnetwork",
						title: "Manage Recruiters' Network",
						type: "item",
						url: "/teamlead/managerecruitersnetwork"
					} : {},
				]
			} : {},
		// } : [],
	] : []

	return (
		<FuseNavigation
			className={clsx('navigation', props.className)}
			navigation={navigationCheck}
			layout={props.layout}
			dense={props.dense}
			active={props.active}
		/>
	);
}

Navigation.defaultProps = {
	layout: 'vertical'
};

export default React.memo(Navigation);
