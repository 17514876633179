import axios from 'axios';
// import * as API from 'app/constants/ApiConstants';
import * as APP from 'app/constants/AppConstants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logoutsession from '../../logout/logoutsession.js';
import refreshTokenApi from '../../refreshtoken/refreshtokenapi';
import { API_URL } from 'app/constants/ApiConstants.js';


export function CandidateChatDescriptionAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API_URL + "/api/results/chatbot/info?jobid=" + data.jobId,
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function CandidateChatBotSendAPI(formData, res) {
    return new Promise((resolve, reject) => {
        axios.post(API_URL +"/api/results/chatbot/get/session?sessionId=" + formData.sessionId, formData,
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}