import React from 'react';

const manageRecruitersNetworkConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path: '/teamlead/managerecruitersnetwork',
            component: React.lazy(() => import('./manageRecruitersNetwork'))
        }
    ]
};

export default manageRecruitersNetworkConfig;