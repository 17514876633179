import * as uploadjobdetailsapi from 'app/services/recruiterapi/uploadjobdetails';
import { database } from 'firebase';


export const CANDIDATESEARCHLIST_ERROR = 'CANDIDATESEARCHDETAILS_ERROR';
export const CANDIDATESEARCHLIST_SUCCESS = 'CANDIDATESEARCHDETAILS_SUCCESS';
export const RUNMATCH_ERROR = "RUNMATCH_ERROR"
export const RUNMATCH_SUCCESS = "RUNMATCH_SUCCESS"


//upload job file upload
export function uploadjobdetailsActions(Data, res) {
	uploadjobdetailsapi.uploadjobdetails(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.uploadjobdetails(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})

}
// upload work auth
export function uploadjobWorkAuthActions(res) {
	uploadjobdetailsapi.uploadjobWorkAuth((response) => {
		if (response.token) {
			uploadjobdetailsapi.uploadjobWorkAuth((response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//uploadjobdetailssearch action
export function uploadjobDetailsSearchActions(data, res) {
	uploadjobdetailsapi.uploadSearch(data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.uploadSearch(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//client job whole list
export function uploaddetailsClientJobActions(data, res) {
	uploadjobdetailsapi.uploaddetailsClientJob(data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.uploaddetailsClientJob(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//uploaddetailsJobStatusActions
// job Status whole list
export function uploaddetailsJobStatusActions(data, res) {
	uploadjobdetailsapi.uploaddetailsJobStatus(data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.uploaddetailsJobStatus(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}


//search
// export function uploadSearchActions(data, res) {
// 	console.log("senduploadaction", data)
// 	return dispatch =>
// 		uploadjobdetailsapi.uploadSearch(data)
// 			.then(response => {
// 				return dispatch({
// 					type: CANDIDATESEARCHLIST_SUCCESS,
// 					payload: response
// 				});
// 			})
// 			.catch(error => {
// 				console.log("errorreducer", error)
// 				return dispatch({
// 					type: CANDIDATESEARCHLIST_ERROR,
// 					payload: error
// 				});
// 			});
// }
//initiate search 
export function uploadInitiateSearchActions(data, res) {
	uploadjobdetailsapi.uploadInitiateSearch(data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.uploadInitiateSearch(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})

}
//search withoutreducer
export function uploadSearchNoReducerActions(data, res) {
	uploadjobdetailsapi.uploadSearchNoReducer(data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.uploadSearchNoReducer(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//upload sovren 
export function UploadSovrenActions(formData, res) {
	uploadjobdetailsapi.UploadSovren(formData, (response) => {
		if (response.token) {
			uploadjobdetailsapi.UploadSovren(formData, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// upload from other source
export function UploadSovrenOtherActions(formData, res) {
	uploadjobdetailsapi.UploadSovrenOtherSrc(formData, (response) => {
		if (response.token) {
			uploadjobdetailsapi.UploadSovrenOtherSrc(formData, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//attachmentedit
export function AttachmentEdit(data, res) {
	uploadjobdetailsapi.AttachmentEditApi(data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.AttachmentEditApi(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}


//jobDescription
export function jobDescriptionActions(formData, res) {
	uploadjobdetailsapi.jobDescription(formData, (response) => {
		if (response.token) {
			uploadjobdetailsapi.jobDescription(formData, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// run matching
export function RunmatchActions(formData) {
	return dispatch =>
		uploadjobdetailsapi.RunMatch(formData)
			.then(response => {
				return dispatch({
					type: RUNMATCH_SUCCESS,
					payload: response
				})
			})
			.catch(error => {
				return dispatch({
					type: RUNMATCH_ERROR,
					payload: error
				})
			});
}
//candidate search  initiate resume link
export function resumeInitiatelinkActions(Data, res) {
	uploadjobdetailsapi.resumeInitiateLink(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.resumeInitiateLink(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

//match results 
//filter by 
export function filterByActions(Data, res) {
	uploadjobdetailsapi.filterBy(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.filterBy(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//view resumes
export function viewResumeActions(Data, jobid, res) {
	uploadjobdetailsapi.viewresume(Data, jobid, (response) => {
		if (response.token) {
			uploadjobdetailsapi.viewresume(Data, jobid, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
export function Candidateviewresume(Data, res) {
	uploadjobdetailsapi.Candidateviewresume(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.Candidateviewresume(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//send bot
export function sendBotActions(Data, res) {
	uploadjobdetailsapi.sendBot(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.sendBot(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// send email
export function sendEmailActions(Data, res) {
	uploadjobdetailsapi.sendEmailAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.sendEmailAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// send job email
export function sendJobEmailActions(Data, res) {
	uploadjobdetailsapi.sendJobEmailAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.sendJobEmailAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// Get Job Grabber
export function getJobGrabberAPI(Data, res) {
	uploadjobdetailsapi.getJobGrabberAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.getJobGrabberAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//Manage Candidate Email Attachment
export function manageCandidateAttachmentAction(data, options, res) {
	uploadjobdetailsapi.manageCandidateAttachmentAPI(data,  options, (response) => {
		if (response.token) {
			uploadjobdetailsapi.manageCandidateAttachmentAPI(data,  options,(response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

//Manage Candidate Email Attachment
export function manageJobAction(data, res) {
	uploadjobdetailsapi.manageJobAPI(data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.manageJobAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// send sms
export function sendSMSActions(Data, res) {
	uploadjobdetailsapi.sendSMSAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.sendSMSAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// upload client formatted resume
export function sendFormattedResumeActions(Data, res) {
	uploadjobdetailsapi.sendFormattedResumeAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.sendFormattedResumeAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// source type
export function SourcetypeAction(Data, res) {
	uploadjobdetailsapi.sourcetypeAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.sourcetypeAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}


export function ClientResumeFormatAction(Data, res) {
	uploadjobdetailsapi.ClientResumeFormat(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.ClientResumeFormat(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

//For accessing client resume format document created
export function ClientResumeFormattedAction(Data, res) {
	uploadjobdetailsapi.ClientResumeFormatted(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.ClientResumeFormatted(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
export function migrateAction(Data, res) {
	uploadjobdetailsapi.migrateAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.migrateAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}


// skip search
export function skipSearchAction(Data, res) {
	uploadjobdetailsapi.skipSearchAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.skipSearchAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// MAtch submit
export function MatchSubmitAction(Data, res) {
	uploadjobdetailsapi.MatchSubmitAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.MatchSubmitAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// BAck to candidate search page
export function BacktoCandidateAction(Data, res) {
	uploadjobdetailsapi.BacktoCandidateAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.BacktoCandidateAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

export function manageCandidateSkillSet(data, res) {
	uploadjobdetailsapi.manageCandidateSkillSetAPI(data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.manageCandidateSkillSetAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// contact info
export function ViewcontactInfoAction(Data, res) {
	uploadjobdetailsapi.ViewcontactInfoAPI(Data, (response) => {
		if (response.token) {
			uploadjobdetailsapi.ViewcontactInfoAPI(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}