import React from 'react';
 {/*Added this code. Anand_POW-492. July 02 2023.. Code Start */}

const ManageSecurityAddRoleConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/securitysetup/adduser',
			component:React.lazy(() => import('./tabs/NewModal')) 
        }
	]
};

export default ManageSecurityAddRoleConfig;

 {/*Added this code. Anand_POW-492. July 02 2023.. Code End */}