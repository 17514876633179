import React from 'react';

const CandidateSearchConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/upload/candidatesearch',
			component:React.lazy(() => import('./CandidateSearch')) 
        }
	]
};

export default CandidateSearchConfig;