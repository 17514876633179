
import * as manageCandidateAPI from 'app/services/recruiterapi/ManageCandidateAPI';

export const MANAGECANDIDATE_ERROR = 'MANAGECANDIDATE_ERROR';
export const MANAGECANDIDATE_SUCCESS = 'MANAGECANDIDATE_SUCCESS';



//upload job file upload
// export function manageCandidateSearchActions(formData, res) {
// 	return dispatch =>
// 		manageCandidateAPI.manageCandidateSearchAPI(formData)
// 			.then(response => {
// 				return dispatch({
// 					type: MANAGECANDIDATE_SUCCESS,
// 					payload: response
// 				});
// 			})
// 			.catch(error => {
// 				return dispatch({
// 					type: MANAGECANDIDATE_ERROR,
// 					payload: error
// 				});
// 			});
// }

//search
export function manageCandidateSearchActions(data, res) {
	manageCandidateAPI.manageCandidateSearchAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.manageCandidateSearchAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// Status list
export function manageCandidateStatusActions(data, res) {
	manageCandidateAPI.manageCandidateStatusAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.manageCandidateStatusAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// Job list
export function manageCandidateJobActions(data, res) {
	manageCandidateAPI.manageCandidateJobAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.manageCandidateJobAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
// resume
export function resumelinkActions(Data, res) {
	manageCandidateAPI.resumeLink(Data, (response) => {
		if (response.token) {
			manageCandidateAPI.resumeLink(Data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// managecandidateLIst
export function manageCandidateDetailsActions(data, res) {
	manageCandidateAPI.manageCandidateDetailsAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.manageCandidateDetailsAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// View SMS
export function manageCandidateViewSMSActions(data, res) {
	manageCandidateAPI.manageCandidateViewSMSAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.manageCandidateViewSMSAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// View Email
export function manageCandidateViewEmailActions(data, res) {
	manageCandidateAPI.manageCandidateViewEmailAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.manageCandidateViewEmailAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

// Submit TAble data
export function manageCandidateUPDATEActions(data, res) {
	manageCandidateAPI.manageCandidateUPDATEAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.manageCandidateUPDATEAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

//create position history 
export function createPositionHistory(data, res) {
	manageCandidateAPI.createPositionHistoryAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.createPositionHistoryAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}

//create skillset
export function createSkillSet(data, res) {
	manageCandidateAPI.createSkillSetAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.createSkillSetAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
/* Added this code for  POW-377. Prasanna. November 28 2023.. Code Start */
//Candidate preferences
export function createCandidatePref(data,res){
	manageCandidateAPI.createCandidatePrefAPI(data, (response) => {
		if(response.token){
			manageCandidateAPI.createCandidatePrefAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
/* Prasanna. November 28 2023. Code End */
// 
export function manageCandidateSkillSet(data, res) {
	manageCandidateAPI.manageCandidateSkillSetAPI(data, (response) => {
		if (response.token) {
			manageCandidateAPI.manageCandidateSkillSetAPI(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}