import { authRoles } from 'app/auth';
import ForgotUser from './ForgotUser';

const ForgotUserConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	// auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/forgotuser',
			component: ForgotUser
		}
	]
};

export default ForgotUserConfig;
