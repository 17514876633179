import * as setupExpensesServices from 'app/services/dashboard/SetupExpensesServices';

export function getClientListActions(data,res) {
	// get client data =>
    setupExpensesServices.getClientList(data,(response)=>{
		if (response.token) {
			setupExpensesServices.getClientList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function addClientListActions(data,res) {
	// add data =>
    setupExpensesServices.addClientList(data,(response)=>{
		if (response.token) {
			setupExpensesServices.addClientList(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function getClientListDataActions(data,res) {
	// get data =>
    setupExpensesServices.getClientListData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.getClientListData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function updateClientListDataActions(data,res) {
	// update data =>
    setupExpensesServices.updateClientListData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.updateClientListData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function addInsurancesDataActions(data,res) {
	// add data =>
    setupExpensesServices.addInsurancesData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.addInsurancesData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function getInsurancesDataActions(data,res) {
	// add data =>
    setupExpensesServices.getInsurancesData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.getInsurancesData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function updateInsurancesDataActions(data,res) {
	// get data =>
    setupExpensesServices.updateInsurancesData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.updateInsurancesData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function getVMSClientListActions(data,res) {
	// get data =>
    setupExpensesServices.getVMSClientListData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.getVMSClientListData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function getVMSListActions(data,res) {
	// get data =>
    setupExpensesServices.getVMSListData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.getVMSListData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function getVMSTableDataActions(data,res) {
	// get data =>
    setupExpensesServices.getVMSTableData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.getVMSTableData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}


export function updateVMSTableDataActions(data,res) {
	// get data =>
    setupExpensesServices.updateVMSTableData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.updateVMSTableData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}


export function addVMSListDataActions(data,res) {
	// get data =>
    setupExpensesServices.addVMSTableData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.addVMSTableData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function addJobBoardDataActions(data,res) {
	// get data =>
    setupExpensesServices.addJobBoardData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.addJobBoardData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function deleteJobBoardDataActions(data,res) {
	// get data =>
    setupExpensesServices.deleteJobBoardData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.deleteJobBoardData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}

export function updateJobBoardDataActions(data,res) {
	// get data =>
    setupExpensesServices.updateJobBoardData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.updateJobBoardData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}
export function getJobBoardDataActions(data,res) {
	// get data =>
    setupExpensesServices.getJobBoardData(data,(response)=>{
		if (response.token) {
			setupExpensesServices.getJobBoardData(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
		})
}