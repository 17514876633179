import axios from 'axios';
import * as API from 'app/constants/ApiConstants'
import * as APP from 'app/constants/AppConstants'
import Logoutsession from '../../logout/logoutsession.js'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import refreshTokenApi from '../../refreshtoken/refreshtokenapi';

//upload file
export function uploadjobdetails(Data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.UPLOADJOBDETAILS_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// upload search
export function uploadSearch(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.JOBSEARCH_URL + Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            )
    })
}
// upload search no reducer
export function uploadSearchNoReducer(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.JOBSEARCH_URL + Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//uploadInitiateSearch
export function uploadInitiateSearch(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.INITIATESEARCH_URL + Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//jobdescription
export function jobDescription(data, res) {
    let isNonHTML = data.isNonHTML ? data.isNonHTML : "False"
    return new Promise((resolve, reject) => {
        axios.get(API.JOBDESCRIPTION_URL + "?jobid=" + data.jobId + "&isNonHTML=" + isNonHTML,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                // if (APP.SUCCESS == response.status) {
                res(response.data)
                // }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//UploadSovren
export function UploadSovren(formData, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.UPLOADSOVRENSELECTED_URL, formData,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    res(error.response.data)
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function UploadSovrenOtherSrc(formData, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.UPLOADSOVREN_URL, formData,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS == response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//attachment edit
export function AttachmentEditApi(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.ATTACHMENTEDITURL + "?candId=" + data.candId + "&description=" + data.description,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// Run match
export function RunMatch(formData, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.RUNMATCHING_URL + formData,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    resolve(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.status) {
                        refreshTokenApi()
                    }
                    else {
                        reject(error.response.data)
                    }
                }
            );
    });
}


// Upload work auth
export function uploadjobWorkAuth(res) {
    return new Promise((resolve, reject) => {
        axios.get(API.UPLOADWORKAUTH_URL,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}



//upload job client job whole list
export function uploaddetailsClientJob(res) {
    return new Promise((resolve, reject) => {
        axios.get(API.UPLOADCLIENTJOB_URL,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//send resume link 
export function resumeInitiateLink(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.DICERESUMEVIEWLINK_URL + Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {

                if (APP.SUCCESS === response.status) {
                    console.log("response.data", response.data)
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//match results
//filter by
export function filterBy(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.FILTERBY_URL + "?jobid=" + Data.jobid + "&sorting=" + Data.sorting + "&sortby=ASC",
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//viewresume
export function viewresume(Data, jobid, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.VIEWRESUMES_URL + "?candguid=" + Data + "&jobid=" + jobid,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
// view resume for candidate search
export function Candidateviewresume(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.VIEWRESUMES_URL + "?candguid=" + Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {

                if (APP.SUCCESS === response.status) {
                    console.log("response.data", response.data)
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//sendBot
export function sendBot(Data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.SENDBOT_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
// send email
export function sendEmailAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.SENDEMAIL_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// send job email
export function sendJobEmailAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.SENDJOBEMAIL_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
// Get Job Grabber
export function getJobGrabberAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.GETJOBGRABBER_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
//Upload email attachments
export function manageCandidateAttachmentAPI(data, options, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.SENDATTACHMENT_URL, data,
            // {  
            //     headers: {
            //         'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token'),
            //         'Content-Type': 'multipart/form-data',
            //     },
            // }
            options
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}


//Upload Job 
//Manage job email attachments
export function manageJobAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.SENDATTACHMENT_URL, data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token'),
                    'Content-Type': 'multipart/form-data',
                },
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// send sms
export function sendSMSAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.SENDSMS_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// upload client formatted resume
export function sendFormattedResumeAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.SENDCLIENTFORMATTEDRESUME_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token'),
                    'content-type': 'multipart/form-data'
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// Get source type
export function sourcetypeAPI(res) {
    return new Promise((resolve, reject) => {
        axios.get(API.SOURCETYPE_URL,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function ClientResumeFormat(Data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.CLIENTRESUMEFORMAT_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    console.log("response.data", response.data)
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//For accessing client resume format document created
export function ClientResumeFormatted(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(Data,
            {
                headers: {
                    // 'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                    // 'Content-Type': 'text/plain',
                    // 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
                    // "Access-Control-Allow-Origin" : "*"                
                    // 'Content-Type': 'application/octet-stream',
                    // 'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    console.log("response.data", response.data)
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function migrateAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.MIGRATE_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    console.log("response.data", response.data)
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}


// export function skipSearchAPI(res) {
//     console.log("resAPI",res)
//     return new Promise((resolve, reject) => {
//         axios.get(API.JOBDETAILSKIPSEARCH_URL,
//             {
//                 headers: {
//                     'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
//                 }
//             }
//         )
//             .then(response => {
//                     res(response)
//             },
//             );
//     });
// }

export function skipSearchAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.JOBDETAILSKIPSEARCH_URL + Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                // if (APP.SUCCESS == response.status) {
                // resolve(response.data)
                // }
                // if(response.status==206){
                resolve(response.data)
                // }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function MatchSubmitAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MATCHSUBMIT_URL + '?candid=' + Data.candid + '&jobid=' + Data.jobid + '&status=Submitted',
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

// 

export function BacktoCandidateAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.BACKTO_CANDIDATE_URL + '?jobid=' + Data.jobid,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function manageCandidateSkillSetAPI(data, res) {
    return new Promise((resolve, reject) => {
        axios.get(API.MANAGECANDIDATESKILLSET_URL + "?candid=" + data.candid,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

export function ViewcontactInfoAPI(Data, res) {
    return new Promise((resolve, reject) => {
        axios.post(API.VIEWCONTACTINFO_URL, Data,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {

                if (APP.SUCCESS === response.status) {
                    console.log("response.data", response.data)
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}

//uploaddetailsJobStatus
//upload job whole list
export function uploaddetailsJobStatus(res) {
    return new Promise((resolve, reject) => {
        axios.get(API.UPLOADJOBSTATUS_URL,
            {
                headers: {
                    'Authorization': "Bearer" + " " + localStorage.getItem('bearer_token')
                }
            }
        )
            .then(response => {
                if (APP.SUCCESS === response.status) {
                    res(response.data)
                }
            },
                (error) => {
                    if (APP.LOGOUTERROR === error.response.status) {
                        Logoutsession()
                        toast("Your session is timed out, not able to proceed further", {
                            position: toast.POSITION.TOP_CENTER,
                            className: 'foo-bar'
                        })
                    }
                    else if (APP.TOKENERROR === error.response.data.erroCode) {
                        refreshTokenApi(error.response.data.refreshToken, (resp) => {
                            localStorage.setItem('bearer_token', resp.token)
                            res(resp)
                        })
                    }
                    else {
                        res(error.response.data)
                    }
                }
            );
    });
}
