import React from 'react';
import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/apps/appsConfigs';
import LoginConfig from 'app/main/login/LoginConfig';
import ForgotPasswordConfig from 'app/main/forgot/ForgotPasswordConfig';
import ForgotUserConfig from 'app/main/forgotuser/ForgotUserConfig';
import RecruiterConfig from 'app/main/recruiter/recruiterConfigs';
// import pagesConfigs from 'app/main/pages/pagesConfigs';
import TeamLeadConfigs from 'app/main/teamlead/TeamLeadConfig';
// ...pagesConfigs,
const routeConfigs = [
	ForgotPasswordConfig,
	...appsConfigs,
	...RecruiterConfig,
	...TeamLeadConfigs,
	ForgotUserConfig,
	LoginConfig,
];

const routes = [
	...FuseUtils.generateRoutesFromConfigs(routeConfigs),
	{
		path: '/',
		component: () => <Redirect to="/" />
	},
	// {
	// 	component: () => <Redirect to="/pages/errors/error-404" />
	// }

];

export default routes;
