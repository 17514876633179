/* Added this code for  POW-521. Prasanna. August 04 2023.. Code Start */
import * as manageVMSPasswordsAPI from 'app/services/teamleadAPI/manageVMSPasswordsApi';
//create business unit
export function VMSPasswordsCreateActions(data,res){
	manageVMSPasswordsAPI.VMSPasswordsCreate(data,(response)=>{
		if (response.token) {
			manageVMSPasswordsAPI.VMSPasswordsCreate(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
//edit business unit
export function VMSPasswordsEditActions(data,res){
	manageVMSPasswordsAPI.VMSPasswordsEdit(data,(response)=>{
		if (response.token) {
			manageVMSPasswordsAPI.VMSPasswordsEdit(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
 /* Added this code for  POW-464. Prasanna. February 02 2023.. Code Start */
//delete business unit
export function VMSPasswordsDeleteActions(data,res){
	manageVMSPasswordsAPI.VMSPasswordsDelete(data,(response)=>{
		if (response.token) {
			manageVMSPasswordsAPI.VMSPasswordsDelete(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
/* Prasanna. February 02 2023. Code End */
//search business unit
export function VMSPasswordsSearchActions(data,res){
	manageVMSPasswordsAPI.VMSPasswordsSearch(data,(response)=>{
		if (response.token) {
			manageVMSPasswordsAPI.VMSPasswordsSearch(data, (response) => {
				return res(response)
			})
		}
		else {
			return res(response)
		}
	})
}
/* Prasanna. August 04 2023. Code End */