import DashboardConfig from './dashboard/DashboardConfig.js';
import ManageClientConfig from './manageClient/manageClientConfig'
import ManageVendorConfig from './manageVendor/manageVendorConfig'
import ManageRecruitersNetworkConfig from './manageRecruitersNetwork/manageRecruitersNetworkConfig'
/* Added this code for  POW-521. Prasanna. August 01 2023.. Code Start */
import ManageVMSPasswordsConfig from './manageVMSPasswords/manageVMSPasswordsConfig.js'
/* Prasanna. August 01 2023. Code End */
const TeamLeadConfigs = [
    DashboardConfig,
    ManageClientConfig,
    ManageVendorConfig,
    /* Added this code for  POW-521. Prasanna. August 01 2023.. Code Start */
    ManageVMSPasswordsConfig,
    /* Prasanna. August 01 2023. Code End */
    ManageRecruitersNetworkConfig
]
export default TeamLeadConfigs;

