import React from 'react';

const UploadJobDetailsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/upload/uploadjobdetails',
			component:React.lazy(() => import('./UploadJobDetails')) 
        }
	]
};

export default UploadJobDetailsConfig;