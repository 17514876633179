import React from 'react';

const BusinessUnitConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/organizationalsetup/setupexpenses',
			component:React.lazy(() => import('./SetupExpenses')) 
        }
	]
};

export default BusinessUnitConfig;