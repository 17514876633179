import React from 'react';

const DashboardConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/recruiter/dashboard',
			component:React.lazy(() => import('./Dashboard')) 
        },
		// Added this code for  POW-102. Khushi. April 10 2023.. Code Start /

	    {
		 	path: '/recruiter/dashboardBackgroundprocess',
		 	component:React.lazy(() => import('./DashboardBackgorundProcess')) 
         },
		{
			path: '/recruiter/automationdashboard',
			component:React.lazy(() => import('./AutomationDashboard')) 
        }
		// Khushi. April 10 2023. Code End /

	]
};

export default DashboardConfig;