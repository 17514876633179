import FuseAnimate from '@fuse/core/FuseAnimate';
import { useForm } from '@fuse/hooks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as authActions from 'app/auth/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    root: {
        background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${theme.palette.primary.dark} 80%)`,
        color: theme.palette.primary.contrastText
    }
}));

function ForgotUserPage() {
    const dispatch = useDispatch();
    const forgotusername = useSelector(({ auth }) => auth.forgot.forgotusernameerror);
    const forgotusernamesuccess = useSelector(({ auth }) => auth.forgot.forgotusernamesuccess);
    console.log("forgotusernamesuccess", forgotusernamesuccess)

    const classes = useStyles();
    const { form, handleChange, resetForm } = useForm({
        email: ''
    });
    const [message, errorFunction] = useState(false)
    const [successmessage, successFunction] = useState(false)

    useEffect(() => {
        console.log('loginsuccessful');
        if (forgotusername && (forgotusername.erroMessage)) {
            errorFunction(true)
            successFunction(false)
        }
        else if (forgotusernamesuccess && (forgotusernamesuccess.message)) {
            successFunction(true)
            errorFunction(false)
            resetForm();
        }
    }, [forgotusername, forgotusernamesuccess]);

    function isFormValid() {
        let email_test = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(form.email);
        return form.email.length > 0 && email_test === true;
    }


    function handleSubmit(ev) {
        console.log("ev", ev)
        ev.preventDefault();
        dispatch(authActions.forgotuserActions(form.email))
        // resetForm();
    }

    return (
        <div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
            <div className="flex flex-col items-center justify-center w-full">
                <FuseAnimate animation="transition.expandIn">
                    <Card className="w-full max-w-384">
                        <CardContent className="flex flex-col items-center justify-center p-32">
                            <div className="w-128 m-32">

                                <img src="assets/images/logos/fuse1.png" alt="logo" />
                            </div>
                            <h2>Forgot username</h2>

                            <Typography variant="p" className="mt-16 mb-32">
                                Enter your email address and your username will be emailed to you
							</Typography>

                            <form
                                name="recoverForm"
                                noValidate
                                className="flex flex-col justify-center w-full"
                                onSubmit={handleSubmit}
                            >
                                <TextField
                                    className="mb-16"
                                    label="Email"
                                    autoFocus
                                    type="email"
                                    name="email"
                                    value={form.username}
                                    onChange={handleChange}
                                    // helperText={validateEmail}
                                    variant="outlined"
                                    // required={true}
                                    fullWidth
                                />
                                {message === true ?
                                    <Alert severity="error">{forgotusername ? forgotusername.erroMessage : ''}</Alert>
                                    :
                                    successmessage === true ?
                                        <Alert severity="success">{forgotusernamesuccess ? forgotusernamesuccess.message : ''}</Alert>
                                        :
                                        ''
                                }
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className="w-224 mx-auto mt-16"
                                    aria-label="Reset"
                                    disabled={!isFormValid()}
                                    type="submit"
                                >
                                    Request username
								</Button>


                            </form>

                            <div className="flex flex-col items-center justify-center pt-32 pb-24">
                                <Link className="font-medium" to="/">
                                    Go back to login
								</Link>
                            </div>
                            <div>
                                @ Copyright 2020 Solomons International, LLC
								</div>
                        </CardContent>
                    </Card>
                </FuseAnimate>
            </div>
        </div>
    );
}

export default ForgotUserPage;
