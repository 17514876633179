import * as Actions from '../actions/recruiteractions';
const initialState = {
    data: [],
    managejobdetailssuccess: false
};
const Managereducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.MAANAGEJOBDETAILS_SUCCESS:
            return {
                ...state, ...{ managejobdetailslistsuccess: action.payload, managejobdetailssuccess: true }
            }
        case Actions.MAANAGEJOBDETAILS_ERROR:
            return {
                ...state, ...{ managejobdetailslisterror: action.payload, managejobdetailssuccess: false }
            }

        default: {
            return state;
        }
    }
}

export default Managereducer;